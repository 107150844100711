import { Injectable } from '@angular/core';
import { AuthOwnHttpClient } from 'src/app/common/api-clients/AuthOwnHttpClient';
import { ApiEndpointClient } from 'src/app/common/api-clients/ApiEndpointClient';
import { MergeFunctionOdm } from 'src/app/types/dataModels/MergeFunctionOdm';
import { VoidResult } from 'src/app/types/helpers/VoidResult';
import { DocumentFormatReq } from 'src/app/types/dataModels/DocumentFormatReq';
import { MergeFunctionUm4PdCategoryReq } from 'src/app/types/dataModels/MergeFunctionUm4PdCategoryReq';
import { MergeFunctionUm4TemplateReq } from '../types/MergeFunctionUm4TemplateReq';
import { MergeFunctionUm4DefaultTemplateReq } from '../types/MergeFunctionUm4DefaultTemplateReq';
import { TemplatesOdm } from 'src/app/types/dataModels/TemplateOdm';
import { CategoryOdm } from 'src/app/types/dataModels/CategoryOdm';
import { DocumentFormatOdm } from 'src/app/types/dataModels/DocumentFormatOdm';
import { MergeFunctionsOdm } from 'src/app/types/dataModels/MergeFunctionsOdm';

@Injectable({ providedIn: "root" })
export class MergeFunctionsApiClient {

  constructor(private apiEndpointClient: ApiEndpointClient, private httpClient: AuthOwnHttpClient) { }

  private mergeFunctionsCache: { [key: number]: MergeFunctionsOdm } = {};
  private globalMergeFunctionsCache: { [key: number]: MergeFunctionsOdm } = {};
  private mergeFunctionCache: { [key: number]: MergeFunctionOdm } = {};  
  private templatesCache: { [key: number]: TemplatesOdm } = {};
  private globalTemplatesCache: { [key: number]: TemplatesOdm } = {};

  clearMergeFunctionCache() {
    this.mergeFunctionCache = {};
  }

  clearMergeFunctionsCache() {
    this.mergeFunctionsCache = {};
    this.globalMergeFunctionsCache = {};
  }

  clearTemplatesCache() {
    this.templatesCache = {};
    this.globalTemplatesCache = {};
  }
  
  async getMergeFunctions(moduleId: number, global: boolean, forceRefresh: boolean = false): Promise<MergeFunctionsOdm> {
    
    if (!global && this.mergeFunctionsCache[moduleId] && !forceRefresh) {
      return Promise.resolve(this.mergeFunctionsCache[moduleId]);
    }

    if (global && this.globalMergeFunctionsCache[moduleId] && !forceRefresh) {
      return Promise.resolve(this.globalMergeFunctionsCache[moduleId]);
    }

    const endpoint = global ? 'global-merge-functions' : `mergefunctions`;

    const url: string = `/module/${moduleId}/${endpoint}`;
    const data = await this.apiEndpointClient.get<MergeFunctionsOdm>(url);

    if (!global) {
      this.mergeFunctionsCache = {};
      this.mergeFunctionsCache[moduleId] = data;
    } else {
      this.globalMergeFunctionsCache = {};
      this.globalMergeFunctionsCache[moduleId] = data;
    }

    return data;
  }

  async getMergeFunction(id: number, forceRefresh: boolean = false): Promise<MergeFunctionOdm> {
    if (this.mergeFunctionCache[id] && !forceRefresh) {      
      return Promise.resolve(this.mergeFunctionCache[id]);
    } else {      
      const url: string = `/mergefunction/${id}`;
      const mergeFunction = await this.apiEndpointClient.get<MergeFunctionOdm>(url);

      // First empty cache
      this.mergeFunctionCache = {};

      // Now store new item
      this.mergeFunctionCache[id] = mergeFunction;            
      return mergeFunction;
    }
  }

  async getMergeFunctionTemplates(id: number, global: boolean, forceRefresh: boolean = false): Promise<TemplatesOdm> {    
    if (!global && this.templatesCache[id] && !forceRefresh) {
      return Promise.resolve(this.templatesCache[id]);
    } 
    if (global && this.globalTemplatesCache[id] && !forceRefresh) {
      return Promise.resolve(this.globalTemplatesCache[id]);
    } 

    const endpoint = global ? '/global-templates' : '/templates';
    let url: string = '/mergefunction/' + id + endpoint;
    let data = await this.apiEndpointClient.get<TemplatesOdm>(url);
    
    if (!global) {
      this.templatesCache = {};
      this.templatesCache[id] = data;
    }

    if (global) {
      this.globalTemplatesCache = {};
      this.globalTemplatesCache[id] = data;
    }
    
    return data;    
  }

  async getMergeFunctionDocumentFormats(id: number): Promise<DocumentFormatOdm> {
    let url: string = '/mergefunction/' + id + '/documentFormats';	
    let data = await this.apiEndpointClient.get<DocumentFormatOdm>(url);
    return data;
  }

  async getMergeFunctionCategories(id: number): Promise<CategoryOdm> {
    let url: string = '/mergefunction/' + id + '/categories';	
    let data = await this.apiEndpointClient.get<CategoryOdm>(url);
    return data;
  }

  async updateMergeFunctionDocumentFormat(documentFormatReq: DocumentFormatReq): Promise<VoidResult> {
    let url: string = '/mergefunction/' + documentFormatReq.mergeFunctionId + '/documentFormat/' + documentFormatReq.documentFormatId;
    await this.apiEndpointClient.put<VoidResult>(url, documentFormatReq);
    return new VoidResult();
  }

  async UpdateMergeFunctionPdCategory(mergeFunctionUm4PdCategoryReq: MergeFunctionUm4PdCategoryReq): Promise<VoidResult> {
    let url: string = '/mergefunction/' + mergeFunctionUm4PdCategoryReq.mergeFunctionId + '/pdCategory';
    await this.apiEndpointClient.put<VoidResult>(url, mergeFunctionUm4PdCategoryReq);
    return new VoidResult();
  }

  async updateMergeFunctionDefaultTemplate(mergeFunctionTemplateUmdb: MergeFunctionUm4DefaultTemplateReq): Promise<VoidResult> {
    
    let url: string = '/mergefunction/' + mergeFunctionTemplateUmdb.mergeFunctionId + '/defaultTemplate';
    await this.apiEndpointClient.put<VoidResult>(url, mergeFunctionTemplateUmdb);
    return new VoidResult();
  }

  // For normal mode
  async updateMergeFunctionTemplateById(mergeFunctionUm4TemplateReq: MergeFunctionUm4TemplateReq, globalTemplate: boolean): Promise<VoidResult> {
    const endpoint = globalTemplate ? '/global-template' : '/template';
    let url: string = '/mergefunction/' + mergeFunctionUm4TemplateReq.mergeFunctionId + endpoint;    
    await this.apiEndpointClient.put<VoidResult>(url, mergeFunctionUm4TemplateReq);
    return new VoidResult();
  }

  // For global mode
  async updateMergeFunctionGlobalTemplateById(templateId: number, isActive?: boolean, isDefault? :boolean) : Promise<VoidResult> {    

    let url: string = '/globaltemplate/' + templateId + '/state';   

    const payload = { TemplateId: templateId };
    if (isActive !== undefined) {
      payload['IsActive'] = isActive;
    }
    if (isDefault !== undefined) {
      payload['IsDefault'] = isDefault;
    }

    await this.apiEndpointClient.put<VoidResult>(url, payload);

    return new VoidResult();
  }
}
