import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class AlertService {

    private errorMessage: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private successMessage: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private warningMessage: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    public hasMessage$: Observable<boolean>;

    private keepAfterRouteChange = false;

    constructor(private router: Router) {

        this.hasMessage$ = combineLatest([
            this.errorMessage,
            this.successMessage,
            this.warningMessage
        ]).pipe(
            map(([error, success, warning]) => !!(error || success || warning))
        );

        // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                    // only keep for a single route change
                    this.keepAfterRouteChange = false;
                } else {
                    // clear alert message
                    this.clear();
                }
            }
        });
    }

    getError(): Observable<string> {
        return this.errorMessage.asObservable();
    }

    getWarning(): Observable<string> {
        return this.warningMessage.asObservable();
    }

    getSuccess(): Observable<string> {
        return this.successMessage.asObservable();
    }

    success(message: string, keepAfterRouteChange = false) {
        this.clear();
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.successMessage.next(message);
    }

    warning(message: string, keepAfterRouteChange = false) {
        this.clear();
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.warningMessage.next(message);
    }

    error(message: string, keepAfterRouteChange = false) {
        this.clear();
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.errorMessage.next(message);
    }

    clear() {
        this.errorMessage.next(null);
        this.successMessage.next(null);
        this.warningMessage.next(null);
        this.keepAfterRouteChange = false;
    }
}
