import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './common/components/page-not-found/page-not-found.component';
import { BaseTemplatesComponent as BaseTemplatesOverviewComponent } from './base-templates/components/base-template-overview/base-templates.component';
import { MergeFunctionOverviewComponent } from './merge-functions/components/merge-function-overview/merge-function-overview.component';
import { MergeFunctionDetailsComponent } from './merge-functions/components/merge-function-details/merge-function-details.component';
import { TemplateEditorComponent } from './templates/components/template-editor/template-editor.component';
import { TemplateDetailsComponent } from './templates/components/template-details/template-details.component';
import { BaseTemplateDetailsComponent } from './base-templates/components/base-template-details/base-template-details.component';
import { BaseTemplateEditorComponent } from './base-templates/components/base-template-editor/base-template-editor.component';
import { TemplateVersionsComponent } from './templates/components/template-versions/template-versions.component';
import { EmptyTemplateEditorComponent } from './common/components/empty-template-editor/empty-template-editor.component';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { SigninPingComponent } from './common/auth/signin-ping.component';
import { LoggedOutComponent } from './common/auth/logged-out.component';
import { ShellComponent } from './shell/components/shell/shell.component';
import { ErrorPageComponent } from './common/components/error-page/error-page.component';
import { canDeactivateGuard } from './common/guards/unsaved-changes.guard';

const routes: Routes = [
  { path: 'signin-ping', component: SigninPingComponent },  
  { path: 'sessionended', component: LoggedOutComponent },
  { path: '', redirectTo: '/modules', pathMatch: 'full' },
  {
    path: 'modules',
    component: ShellComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
    children: [
      {
        path: ':moduleId/details',
        component: MergeFunctionOverviewComponent,
        data: { helpText: 'mergeFunctionDescText' }
      },
      {
        path: ':moduleId/merge-functions/:mergeFunctionId/details',
        component: MergeFunctionDetailsComponent,
        data: { helpText: 'templateDescText' }
      },
      {
        path: ':moduleId/merge-functions/:mergeFunctionId/templates/:templateId/details',
        component: TemplateDetailsComponent,
        data: { helpText: 'templateDetailsDescText' }
      },
      {
        path: ':moduleId/merge-functions/:mergeFunctionId/templates/:templateId/editor',
        component: TemplateEditorComponent,
        data: { helpText: 'templateEditorDescText' },
        canDeactivate: [canDeactivateGuard]
      },
      {
        path: ':moduleId/merge-functions/:mergeFunctionId/templates/:templateId/versions',
        component: TemplateVersionsComponent
      }
    ]
  },
  {
    path: 'base-templates',
    component: ShellComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
    children: [      
      {
        path: '',
        redirectTo: 'details',
        pathMatch: 'full'
      },
      {
        path: 'details',
        component: BaseTemplatesOverviewComponent,
        data: { helpText: 'baseTemplateDescText' }
      },
      {
        path: ':baseTemplateId/details',
        component: BaseTemplateDetailsComponent,
        data: { helpText: 'baseTemplateDetailsDescText' }
      },
      {
        path: ':baseTemplateId/editor',
        component: BaseTemplateEditorComponent,
        data: { helpText: 'baseTemplateEditorDescText' },
        canDeactivate: [canDeactivateGuard]
      },
      {
        path: ':baseTemplateId/empty-editor',
        component: EmptyTemplateEditorComponent,
        canDeactivate: [canDeactivateGuard]
      }
    ]
  }, 
  {
    path: 'global-modules',
    component: ShellComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
    children: [
      {
        path: ':moduleId/details',
        component: MergeFunctionOverviewComponent,
        data: { helpText: 'mergeFunctionDescText', isGlobal: true }
      },
      {
        path: ':moduleId/merge-functions/:mergeFunctionId/details',
        component: MergeFunctionDetailsComponent,
        data: { helpText: 'templateDescText', isGlobal: true }
      },
      {
        path: ':moduleId/merge-functions/:mergeFunctionId/templates/:templateId/details',
        component: TemplateDetailsComponent,
        data: { helpText: 'templateDetailsDescText', isGlobal: true }
      },
      {
        path: ':moduleId/merge-functions/:mergeFunctionId/templates/:templateId/editor',
        component: TemplateEditorComponent,
        data: { helpText: 'templateEditorDescText', isGlobal: true },
        canDeactivate: [canDeactivateGuard]
      },
      {
        path: ':moduleId/merge-functions/:mergeFunctionId/templates/:templateId/versions',
        component: TemplateVersionsComponent,
        data: { isGlobal: true }
      }
    ]
  },
  
  { path: 'error', component: ErrorPageComponent }, 
  { path: '404', component: PageNotFoundComponent },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
