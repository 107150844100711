import { OnInit, Directive, QueryList, ElementRef } from "@angular/core";
import { ComponentBase } from "./ComponentBase";
import { ComponentService } from "src/app/services/ComponentService";
import { ITableRow } from "../interfaces/ITableRow";
import { TableService, TableConfig } from "../services/TableService";
import { DataPage } from "../types/helpers/DataPage";

@Directive()
export abstract class OverviewComponentBase<TRow extends ITableRow> extends ComponentBase implements OnInit {
  pageIndex: number = 0;
  searchText: string;
  dataPage: DataPage<TRow> = new DataPage<TRow>();
  displayMode = "Table";
  canAddNewRow: boolean = false;
  allRows: TRow[] = [];

  protected abstract get tableRows(): QueryList<ElementRef>;

  constructor(private tableService: TableService<TRow>, componentService: ComponentService) {
    super(componentService);
  }

  async ngOnInit() {
    await this.reloadView();
  }

  async highlightRow(id: any) {
    let self = this;

    // Verkrijg de gefilterde en gesorteerde rijen
    const filteredRows = this.tableService.filterRows(this.allRows, this.tableConfig.filterConfig);
    const sortedRows = this.tableService.sortRows(filteredRows, this.tableConfig.sortConfig);

    // Zoek de index van de rij met het gegeven id
    const rowIndex = sortedRows.findIndex(row => row.id === id);
    if (rowIndex === -1) {
      console.error(`Row with id ${id} not found after filtering and sorting.`);
      return;
    }

    // Bepaal de juiste pagina
    const pageSize = 50;
    const pageIndex = Math.floor(rowIndex / pageSize);

    // Update de pagina en ververs de view
    this.pageIndex = pageIndex;
    this.tableConfig.pageConfig.pageIndex = pageIndex;
    this.refreshView();

    // Wacht tot de view is bijgewerkt en de tableRows zijn gevuld
    setTimeout(() => {
      const visibleRowIndex = this.dataPage.rows.findIndex(row => row.id === id);
      if (visibleRowIndex === -1) {
        console.error(`Row with id ${id} not found in dataPage.rows.`);
        return;
      }

      const rowsArray = self.tableRows.toArray();
      const rowElementRef = rowsArray[visibleRowIndex];

      if (rowElementRef && rowElementRef.nativeElement) {
        const nativeElement = rowElementRef.nativeElement;

        // Scroll naar de rij
        nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });

        // Voeg de highlight-klasse toe
        nativeElement.classList.add('highlight');

        // Verwijder de highlight-klasse na 2 seconden
        setTimeout(() => {
          nativeElement.classList.remove('highlight');
        }, 2000);
      } else {
        console.error(`Could not access nativeElement of the row.`);
      }
    }, 0);
  }
  

  onPageIndexChanged(index: number) {
    this.pageIndex = index;
    this.refreshView();
  }

  onSearchTextChanged(searchText: string) {
    this.searchText = searchText;
    this.pageIndex = 0;
    this.tableConfig.pageConfig.pageIndex = 0;
    this.refreshView();
  }

  onSelectRowClicked(row: TRow) {
    this.dataPage.selectRow(row);
    // console.log(row)
  }

  abstract onDeleteRowClicked(row: TRow);

  abstract onAddNewRowClicked(arg);

  abstract onEditRowClicked(row: TRow);

  abstract onViewRowClicked(row: TRow);

  abstract loadRows(): Promise<TRow[]>;

  abstract get tableConfig(): TableConfig;

  getPage(): DataPage<TRow> {

    if (!this.allRows || this.allRows.length == 0) {
      return new DataPage<TRow>();
    }

    let page = this.tableService.getDataPage(this.allRows, this.tableConfig);
    return page;
  }

  async reloadView(): Promise<void> {
    this.allRows = await this.loadRows();
    this.refreshView();
  }

  refreshView(): void {
    this.dataPage = this.getPage();
  }

  updateRows(rows: TRow[]) {
    this.allRows = rows;
  }
}
