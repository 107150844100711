import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OverviewComponentBase } from 'src/app/common/components/OverviewComponentBase';
import { JobService } from 'src/app/common/services/JobService';
import { TableService, TableConfig } from 'src/app/common/services/TableService';
import { GetMergeFunctionCmdlet } from 'src/app/merge-functions/cmdlets/GetMergeFunctionCmdlet';
import { GetModulesCmdlet } from 'src/app/modules/cmdlets/GetModulesCmdlet';
import { ComponentContext } from 'src/app/services/ComponentContext';
import { ComponentService } from 'src/app/services/ComponentService';
import { DocumentFormatOvm } from 'src/app/types/dataModels/DocumentFormatOvm';
import { MergeFunctionOvm } from 'src/app/types/dataModels/MergeFunctionOvm';
import { TemplateDdm } from 'src/app/types/dataModels/TemplateDdm';
import { ModuleOvm } from 'src/app/types/viewModels/ModuleOvm';
import { TemplateOvm } from 'src/app/types/viewModels/TemplateOvm';
import { RevertTemplateVersionCmdlet } from '../../cmdlets/RevertTemplateVersionCmdlet';
import { GetTemplateFileMetaByIdJob } from '../../jobs/GetTemplateFileMetaByIdJob';
import { TemplateVersionRevertIm } from '../../types/TemplateVersionRevertIm';
import { MatSort } from '@angular/material/sort';
import { AlertService } from 'src/app/common/services/AlertService';
import { AlertTypeEnum } from 'src/app/common/types/enums/alertType.enum';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from 'src/app/common/api-clients/session.service';
import { TemplatesApiClient } from '../../api-clients/TemplatesApiClient';
import { UserSession } from 'src/app/common/types/models/user-session.model';
import { Breadcrumb } from 'src/app/common/components/bread-crumbs/bread-crumbs.component';
import { AppInsightsLoggerService } from 'src/app/services/logging.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-template-versions',
  templateUrl: './template-versions.component.html',
  styleUrl: './template-versions.component.scss'
})
export class TemplateVersionsComponent extends OverviewComponentBase<TemplateOvm> implements OnInit {

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private translate: TranslateService,
    componentService: ComponentService,
    private getModulesCmdlet: GetModulesCmdlet,    
    private getMergeFunctionCmdlet: GetMergeFunctionCmdlet,    
    private revertTemplateVersionCmdlet: RevertTemplateVersionCmdlet,    
    public componentContext: ComponentContext,    
    private jobService: JobService,
    private job: GetTemplateFileMetaByIdJob,
    tableService: TableService<TemplateOvm>,
    private templateApi: TemplatesApiClient,
    private logger: AppInsightsLoggerService,
    private alertService: AlertService) {
    super(tableService, componentService)
  }

  moduleId: number;
  moduleName: string;	
  modules: ModuleOvm[];  
  mergeFunctions: MergeFunctionOvm[];
  mergeFunction: MergeFunctionOvm;
  selectedModuleId: number;
  selectedMergeFunctionId: number;
  selectedCategory: string;
  documentFormats: DocumentFormatOvm[];
  templateId: number;
  template: TemplateDdm;
  maxId: number;
  displayedColumns: string[] = ['version', 'createdBy', 'creationDate', 'isActive'];
  searchColumns: string[] = ["createdBy"];
  myUser: UserSession;
  globalMode: boolean = false;
  breadcrumbs: Breadcrumb[];

  private text_error_template_set_active: string;
  private text_templates: string;
  private text_mergefunctions: string;
  private text_global_mergefunctions: string;
  private text_versions: string;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChildren('tableRow') tableRowsRef: QueryList<ElementRef>;

  protected get tableRows(): QueryList<ElementRef> {
    return this.tableRowsRef;
  }

  async ngOnInit() {

    this.myUser = this.sessionService.session;

    super.ngOnInit();

    this.translate.get([           
      'errors.template_set_active',
      'headers.templates',
      'headers.mergefunctions',
      'headers.global_mergefunctions',
      'labels.versions'
    ]).subscribe(translations => {           
      this.text_error_template_set_active = translations['errors.template_set_active'];
      this.text_templates = translations['headers.templates'];
      this.text_mergefunctions = translations['headers.mergefunctions'];
      this.text_global_mergefunctions = translations['headers.global_mergefunctions'];
      this.text_versions = translations['labels.versions'];
    });

    this.route.data.subscribe((data) => {      
      this.globalMode = !!data?.isGlobal;      
    });

    this.route.paramMap.subscribe(async params => {
      this.isLoading = true;

      this.moduleId = Number(params.get('moduleId'));
      const mergeFunctionId = Number(params.get('mergeFunctionId'));
      this.templateId = Number(params.get('templateId'));

      const modules = await this.getModulesCmdlet.execute(false);
      this.moduleName = modules.find(x => x.id == this.moduleId).name;

      this.mergeFunction = await this.getMergeFunctionCmdlet.execute({id: mergeFunctionId, forceRefresh: false});

      await this.updateVersionList(false);

      const baseUrl = this.globalMode ? '/global-modules' : '/modules';

      this.breadcrumbs = [
        { label: this.moduleName },
        { label: this.globalMode ? this.text_global_mergefunctions : this.text_mergefunctions, url: [baseUrl, this.moduleId, 'details'], params: { mid: this.mergeFunction.id } },
        { label: this.mergeFunction.name },
        { label: this.text_templates, url: [baseUrl, this.moduleId, 'merge-functions', this.mergeFunction.id, 'details'], params: { tid: this.templateId }},
        { label: this.template.raetName, url: [baseUrl, this.moduleId, 'merge-functions', this.mergeFunction.id, 'templates', this.templateId, 'details'] },
        { label: this.text_versions }
      ];

      this.isLoading = false;
    });
  }

  showAlert(type: AlertTypeEnum, text: string) {
    this.alertService.setAlert({ type, text });
  }

  onDeleteRowClicked(row: TemplateOvm) {
    throw new Error('Method not implemented.');
  }
  onAddNewRowClicked(arg: any) {
    throw new Error('Method not implemented.');
  }
  onEditRowClicked(row: TemplateOvm) {
    throw new Error('Method not implemented.');
  }
  onViewRowClicked(row: TemplateOvm) {
    throw new Error('Method not implemented.');
  }

  async loadRows(): Promise<TemplateOvm[]> {   
    return null;
  }

  get tableConfig(): TableConfig {
    let config: TableConfig = {
      filterConfig: {
        columns: this.searchColumns,
        searchText: this.searchText,
      },
      sortConfig: {
        sortColumn: this.sort.active,
        sortDirection: this.sort.direction,
      },
      pageConfig: {
        pageIndex: this.pageIndex,
      }
    };
    return config;
  }

  async setActive($event) {
    
    this.isLoading = true;

    try {
      var templateVersionRevertIm = new TemplateVersionRevertIm();
      templateVersionRevertIm.templateId = $event.sourceFileId;
      templateVersionRevertIm.templateVersion = $event.id;
      
      await this.revertTemplateVersionCmdlet.execute(templateVersionRevertIm);
      await this.updateVersionList(true);

      this.templateApi.clearTemplateCache();

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      console.error(error);
      this.logger.error('Error getting template versions', error);
      this.showAlert(AlertTypeEnum.warning, this.text_error_template_set_active);
    }    
  }

  private async updateVersionList(forceRefresh: boolean) {
    this.template = await this.jobService.runJob<GetTemplateFileMetaByIdJob, { templateId: number, forceRefresh?: boolean }, TemplateDdm>(this.job, {templateId: this.templateId, forceRefresh}); 
    
    this.translate.get(this.globalMode ? 'pageTitles.global_template_versions' : 'pageTitles.template_versions', { templateName: this.template.raetName }).subscribe((title: string) => {
      this.titleService.setTitle(title);
    });
      
    this.template.versions.sort((a, b) => b.id - a.id);
    this.maxId = this.template.versions[0].id;    
  }
}
