import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TemplateEditorFileModel } from 'src/app/base-templates/types/helpers/TemplateEditorFileModel';
import { ComponentService } from 'src/app/services/ComponentService';
import { FileTypeEnum } from '../../types/enums/FileTypeEnum';
import { DialogComponentBase } from '../DialogComponentBase';
import { TemplateOvm } from 'src/app/types/viewModels/TemplateOvm';
import { MergeFunctionOvm } from 'src/app/types/dataModels/MergeFunctionOvm';
import { UpdateTemplateContentCmdlet } from 'src/app/templates/cmdlets/UpdateTemplateContentCmdlet';
import { TemplateVersionFromTemplateIm } from 'src/app/templates/types/TemplateVersionFromTemplateIm';
import CreateTemplateVersionFromIdCmdlet from 'src/app/templates/cmdlets/CreateTemplateVersionFromIdCmdlet';
import { TemplateDdm } from 'src/app/types/dataModels/TemplateDdm';
import { ActivatedRoute } from '@angular/router';
import { GetBaseTemplatesCmdlet } from 'src/app/base-templates/cmdlets/GetBaseTemplatesCmdlet';
import { VoidArg } from 'src/app/types/helpers/VoidArg';
import { GetMergeFunctionTemplatesCmdlet } from 'src/app/merge-functions/cmdlets/GetMergeFunctionTemplatesCmdlet';
import { AlertService } from 'src/app/services/AlertService';

@Component({
  selector: 'app-template-redo',  
  templateUrl: './template-redo.component.html',
  styleUrl: './template-redo.component.scss'
})
export class TemplateRedoComponent extends DialogComponentBase<TemplateRedoComponent, boolean> implements OnInit {

  formGroup: UntypedFormGroup;
  templateFile: Blob;
  selectedTemplateIdToCopy: number;
  baseTemplates: TemplateOvm[];  
  templates: TemplateOvm[];  
  selectedTemplateType: FileTypeEnum;
  mergeFunction: MergeFunctionOvm;
  fileExtension: string;
  currentStepId: string = "start-step";
  templateVersions: TemplateDdm;
  mergeFunctionId: number;
  defaultTemplateId: number | null;
  alertToBeDisplayed: boolean = false;

  config: any = {
    'use-default-template': ['start-step', 'select-default-template-step'],
    'copy-this-template': ['start-step', 'select-template-step'],
    'use-local-template': ['start-step', 'select-file-step']
  };

  constructor(private formBuilder: UntypedFormBuilder,
    componentService: ComponentService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private cd: ChangeDetectorRef,
    dialogRef: MatDialogRef<TemplateRedoComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private getBaseTemplatesCmdlet: GetBaseTemplatesCmdlet,        
    private updateTemplateContentCmdlet: UpdateTemplateContentCmdlet,
    private createTemplateVersionFromIdCmdlet: CreateTemplateVersionFromIdCmdlet,
    private getMergeFunctionTemplatesCmdlet: GetMergeFunctionTemplatesCmdlet
  ) {
    super(componentService, dialogRef);
    this.mergeFunctionId = data.mergeFunctionId;
    this.templateVersions = data.templateVersions;
  }

  @ViewChild('search') search: ElementRef;

  async ngOnInit() {

    this.alertService.hasMessage$.subscribe((hasMessage) => {
      this.alertToBeDisplayed = hasMessage;
    });

    super.ngOnInit();
    
    // this.baseTemplates = await this.getBaseTemplatesCmdlet.execute(new VoidArg());
   
    this.formGroup = this.formBuilder.group({
      description: ['', []],
      name: ['', []],
      //selectedFolder: ['', []],

      //selectedTemplateLocation: ['', []],
      selectedTemplate: ['', []],
      wizardType: ['use-default-template'],
    });
        
    this.isLoaded = true;
  }

  async onFormSubmitted() {
    switch (this.wizardType) {

      case 'use-default-template': {        
        // Make a copy of this.selectedTemplateIdToCopy and add it as a new version of this.templateVersions.id
        let templateVersionFromTemplateIm = new TemplateVersionFromTemplateIm();
        templateVersionFromTemplateIm.templateIdTarget = this.templateVersions.id;
        templateVersionFromTemplateIm.templateId = this.selectedTemplateIdToCopy;
        await this.createTemplateVersionFromIdCmdlet.execute(templateVersionFromTemplateIm);
        break;
      }

      case 'copy-this-template': {        
        // Make a copy of this.selectedTemplateIdToCopy and add it as a new version of this.templateVersions.id
        let templateVersionFromTemplateIm = new TemplateVersionFromTemplateIm();
        templateVersionFromTemplateIm.templateIdTarget = this.templateVersions.id;
        templateVersionFromTemplateIm.templateId = this.selectedTemplateIdToCopy;
        await this.createTemplateVersionFromIdCmdlet.execute(templateVersionFromTemplateIm);
        break;
      }

      case 'use-local-template': {
        let model: TemplateEditorFileModel = await this.getModel();
        model.id = this.templateVersions.id;
        model.documentType = this.fileExtension;
        this.updateTemplateContentCmdlet.execute(model);
        break;
      }
    }

    // TODO: handle close
    //await this.routerService.templateDone();
    this.result = true;
    this.close();
    return;
  }

  async onNextClicked(event) {
    if (!this.validateStep(this.currentStepId)) {
      return;
    }

    const currentStepId = this.currentStepId;
    const wizardConfig: string[] = this.config[this.wizardType];
    const currentIndex = wizardConfig.findIndex(x => x == currentStepId);
    const isLastStep = currentIndex == wizardConfig.length - 1;

    if (!isLastStep) {
      this.currentStepId = wizardConfig[currentIndex + 1];

      if (this.currentStepId == 'select-default-template-step') {
        if (!this.baseTemplates) {
          this.baseTemplates = await this.getBaseTemplatesCmdlet.execute(new VoidArg());
        }
      }

      if (this.currentStepId == 'select-template-step') {
        if (!this.templates) {
          this.templates = await this.getMergeFunctionTemplatesCmdlet.execute({ mergeFunctionId: this.mergeFunctionId, global: false, forceRefresh: false });  
        }
      }

      event.preventDefault();
    }
    else {
      this.onFormSubmitted();
    }

  }

  onPrevClicked(event) {
    const currentStepId = this.currentStepId;
    const wizardConfig: string[] = this.config[this.wizardType];
    const currentIndex = wizardConfig.findIndex(x => x == currentStepId);
    this.currentStepId = wizardConfig[currentIndex - 1];
    event.preventDefault();
  }

  onTemplateLocationChanged() {

    

    //this.f.selectedFolder.setValue(undefined);

    // if (this.selectedTemplateLocation == TemplateLocationEnum.Global) {
    //   this.f.selectedDepartment.setValue(undefined);
    //   this.folders = this.allFolders.filter(x => !x.id);
    // }

    // if (this.selectedTemplateLocation == TemplateLocationEnum.Unit) {
    //   this.f.selectedDepartment.setValue(undefined);
    //   this.folders = [];
    // }
  }

  onTemplateChange($event) {    
    this.selectedTemplateIdToCopy = $event.target.value;    
  }

  onDefaultTemplateChange($event) {
    this.selectedTemplateIdToCopy = $event.target.value;    
  }

  onFileChange(event) {

    let reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.f.name.setValue(event.target.files[0].name);
      let extension = event.target.files[0].name;
      this.fileExtension = extension.split('.').pop();      
      reader.readAsBinaryString(file);
      reader.onload = () => {

        this.templateFile = file;
        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();
      };
    }
  }

  onUploadFileClicked(event) {
    document.getElementById("select-file").click();    
    event.preventDefault();
  }

  get f() { return this.formGroup.controls; }

  get wizardType(): string {
    return this.f.wizardType.value;
  }

  get selectedFileName() {
    return this.f.name.value;
  }

  private async getModel(): Promise<TemplateEditorFileModel> {

    const model = new TemplateEditorFileModel();
    model.name = this.f.name.value;
    model.description = this.f.description.value;
    //model.fileType = this.selectedTemplateType;
    //model.mergeFunctionId = this.localStorage.mergeFunction.id;

    if (this.templateFile) {
      model.content = await this.blobToBase64Async(this.templateFile);
    }

    if (this.f.selectedTemplate?.value) {
      const baseTemplateId: number = this.f.selectedTemplate.value;
      // const sourceTemplateFile = await this.getTemplateEditorModelCmdlet.execute(baseTemplateId);
      // model.content = sourceTemplateFile.content;
      //model.placeholders = sourceTemplateFile.placeholders;
    }

    return model;
  }

  async blobToBase64Async(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onerror = (e) => reject(fileReader.error);
      fileReader.onloadend = (e) => {
        const dataUrl = fileReader.result as string;
        // remove "data:mime/type;base64," prefix from data url
        const base64 = dataUrl.substring(dataUrl.indexOf(',') + 1);
        resolve(base64);
      };
      fileReader.readAsDataURL(blob);
    });
  }

  private validateStep(stepId: string): boolean {

    if (stepId == 'general-info-step') {
      return this.validateGenerelInfoStep();
    }

    if (stepId == 'select-default-template-step') {
      return this.validateSelectTemplateStep();
    }

    return true;
  }

  validateSelectTemplateStep(): boolean {

    let result = true;

    if (!this.f.selectedTemplate.value) {
      this.f.selectedTemplate.setErrors({ required: true });
      result = false;
    }

    return result;
  }

  validateGenerelInfoStep(): boolean {

    let result = true;

    if (!this.f.name.value) {
      this.f.name.setErrors({ required: true });
      result = false;
    }

    return result;

  }
}

