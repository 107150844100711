import { Injectable } from "@angular/core";
import { IJob } from "src/app/authentication/interfaces/IJob";
import { MergeFunctionsApiClient } from "../api-clients/MergeFunctionsApiClient";
import { VoidResult } from "src/app/types/helpers/VoidResult";
import { MergeFunctionTemplateUmdb } from "../types/MergeFunctionTemplateUmdb";
import { MergeFunctionUm4DefaultTemplateReq } from "../types/MergeFunctionUm4DefaultTemplateReq";
import { MergeFunctionUm4TemplateReq } from "../types/MergeFunctionUm4TemplateReq";

@Injectable({ providedIn: 'root' })
export class UpdateMergeFunctionDefaultTemplateJob implements IJob<{model: MergeFunctionUm4DefaultTemplateReq, globalMode: boolean, globalTemplate: boolean, makeDefault: boolean}, VoidResult> {

  constructor(private mergeFunctionsApiClient: MergeFunctionsApiClient) { }

  async execute(arg: {model: MergeFunctionUm4DefaultTemplateReq, globalMode: boolean, globalTemplate: boolean, makeDefault: boolean}): Promise<VoidResult> {
    
    if (arg.globalMode) {
      await this.mergeFunctionsApiClient.updateMergeFunctionGlobalTemplateById(arg.model.templateId, arg.makeDefault ? true: undefined, arg.makeDefault);
    } else {
      if (arg.globalTemplate) {
        const model = new MergeFunctionUm4TemplateReq();
        model.templateId = arg.model.templateId;
        model.isDefault = arg.makeDefault;
        model.isActive = arg.makeDefault ? true : undefined;
        model.mergeFunctionId = arg.model.mergeFunctionId;
        await this.mergeFunctionsApiClient.updateMergeFunctionTemplateById(model, true);    
      } else {
        if (arg.makeDefault) {
         await this.mergeFunctionsApiClient.updateMergeFunctionDefaultTemplate(arg.model);
        } else {
          // Remove default flag from template by makeing it inactive and then active again
          const model = new MergeFunctionUm4TemplateReq();
          model.templateId = arg.model.templateId;
          model.isActive = false;
          model.mergeFunctionId = arg.model.mergeFunctionId;
          await this.mergeFunctionsApiClient.updateMergeFunctionTemplateById(model, false);

          model.isActive = true;
          await this.mergeFunctionsApiClient.updateMergeFunctionTemplateById(model, false);
        }
      }
    }
    
    return VoidResult;
  }
}
