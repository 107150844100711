import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { DownloadATemplateCmdlet } from 'src/app/base-templates/cmdlets/DownloadATemplateCmdlet';
import { OverviewComponentBase } from 'src/app/common/components/OverviewComponentBase';
import { TableConfig, TableService } from 'src/app/common/services/TableService';
import { GetMergeFunctionCmdlet } from 'src/app/merge-functions/cmdlets/GetMergeFunctionCmdlet';
import { GetModulesCmdlet } from 'src/app/modules/cmdlets/GetModulesCmdlet';
import { ComponentContext } from 'src/app/services/ComponentContext';
import { ComponentService } from 'src/app/services/ComponentService';
import { DocumentFormatOvm } from 'src/app/types/dataModels/DocumentFormatOvm';
import { MergeFunctionOvm } from 'src/app/types/dataModels/MergeFunctionOvm';
import { ModuleOvm } from 'src/app/types/viewModels/ModuleOvm';
import { TemplateOvm } from 'src/app/types/viewModels/TemplateOvm';
import { TemplateDdm } from 'src/app/types/dataModels/TemplateDdm';
import { ModuleDdm } from 'src/app/types/dataModels/ModuleDdm';
import { DownloadSourceFileReq } from 'src/app/base-templates/types/helpers/DownloadSourceFileReq';
import { UpdateTemplateFileMetaCmdlet } from '../../cmdlets/UpdateTemplateFileMetaCmdlet';
import { TemplateUpdate4Meta } from '../../types/TemplateUpdate4Meta';
import { TemplateRedoComponent } from 'src/app/common/components/template-redo/template-redo.component';
import { TemplateVersionRevertIm } from '../../types/TemplateVersionRevertIm';
import { RevertTemplateVersionCmdlet } from '../../cmdlets/RevertTemplateVersionCmdlet';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { AlertService } from 'src/app/common/services/AlertService';
import { AlertTypeEnum } from 'src/app/common/types/enums/alertType.enum';
import { DeleteTemplateCmdlet } from '../../cmdlets/DeleteTemplateCmdlet';
import { TemplateDelm } from '../../types/TemplateDelm';
import { MergeFunctionsApiClient } from 'src/app/merge-functions/api-clients/MergeFunctionsApiClient';
import { SelectOption } from 'src/app/common/types/helpers/SelectOption';
import { GetTemplateFileMetaByIdCmdlet } from '../../cmdlets/GetTemplateFileMetaByIdCmdlet';
import { TranslateService } from '@ngx-translate/core';
import { TemplatesApiClient } from '../../api-clients/TemplatesApiClient';
import { ConfirmationDialogComponent, DialogButtonType } from 'src/app/common/components/confirmation-dialog/confirmation-dialog.component';
import { SessionService } from 'src/app/common/api-clients/session.service';
import { UserSession } from 'src/app/common/types/models/user-session.model';
import { Breadcrumb } from 'src/app/common/components/bread-crumbs/bread-crumbs.component';
import { AppInsightsLoggerService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-template-details',
  templateUrl: './template-details.component.html',
  styleUrls: ['./template-details.component.scss']
})
export class TemplateDetailsComponent extends OverviewComponentBase<TemplateOvm> implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    componentService: ComponentService,
    private titleService: Title,
    private translate: TranslateService,
    private getModulesCmdlet: GetModulesCmdlet,        
    private getMergeFunctionCmdlet: GetMergeFunctionCmdlet,
    private downloadATemplateCmdlet: DownloadATemplateCmdlet,
    private mergFunctionsApiClient: MergeFunctionsApiClient,
    private templatesApiClient: TemplatesApiClient,
    private deleteTemplateCmdlet: DeleteTemplateCmdlet,
    private getTemplateFileMetaCmdlet: GetTemplateFileMetaByIdCmdlet,       
    private revertTemplateVersionCmdlet: RevertTemplateVersionCmdlet,
    private updateTemplateFileMetaCmdlet: UpdateTemplateFileMetaCmdlet,
    private logger: AppInsightsLoggerService,
    public componentContext: ComponentContext,
    private dialog: MatDialog,    
    private userSession: SessionService,
    private _sanitizer: DomSanitizer,
    tableService: TableService<TemplateOvm>,
    private alertService: AlertService) {
    super(tableService, componentService)
  }

  moduleId: number;  
  moduleName: string;

  modules: ModuleOvm[];
  module: ModuleDdm;
  mergeFunctions: MergeFunctionOvm[];
  mergeFunction: MergeFunctionOvm;
  
  selectedMergeFunctionId: number;
  selectedCategory: string;
  documentFormats: DocumentFormatOvm[];
  mergeFunctionId: number;
  templateId: number;
  templateVersions: TemplateDdm;
  imagePath: any;
  actionBusy = false;
  exportItems: SelectOption[] = [];
  myUser: UserSession;
  globalMode: boolean;
  breadcrumbs: Breadcrumb[];
  isBusy: boolean;
  isError: boolean;
  editableRaetName: string;
  editableDescription: string;
    
  private baseUrl: string;
  private text_error_deleting_template: string;
  private text_error_saving_template: string;
  private text_template_saved: string;
  private text_delete_confirmation: string;
  private text_templates: string;
  private text_mergefunctions: string;
  private text_global_mergefunctions: string;
  private text_name_desc_required: string;
  private text_retrieving_data: string;

  @ViewChildren('tableRow') tableRowsRef: QueryList<ElementRef>;

  protected get tableRows(): QueryList<ElementRef> {
    return this.tableRowsRef;
  }

  async ngOnInit() {  

    this.isBusy = true;
    this.isError = false;

    this.myUser = this.userSession.session;

    this.translate.get([
      'labels.export_to_docx', 
      'labels.export_to_doc', 
      'labels.export_to_bin',
      'errors.deleting_template',
      'errors.saving_template',
      'messages.template_saved',
      'messages.delete_confirmation',
      'headers.templates',
      'headers.mergefunctions',
      'headers.global_mergefunctions',
      'errors.name_desc_required',
      'errors.retrieving_data'
    ]).subscribe(translations => {
      this.exportItems = [
        { id: '.docx', text: translations['labels.export_to_docx'] },
        { id: '.doc', text: translations['labels.export_to_doc'] },
        { id: '.bin', text: translations['labels.export_to_bin'] }
      ];
      this.text_error_deleting_template = translations['errors.deleting_template'];
      this.text_error_saving_template = translations['errors.saving_template'];
      this.text_template_saved = translations['messages.template_saved'];
      this.text_delete_confirmation = translations['messages.delete_confirmation'];
      this.text_templates = translations['headers.templates'];
      this.text_mergefunctions = translations['headers.mergefunctions'];
      this.text_global_mergefunctions = translations['headers.global_mergefunctions'];
      this.text_name_desc_required = translations['errors.name_desc_required'];
      this.text_retrieving_data = translations['errors.retrieving_data'];
    });

    this.route.data.subscribe((data) => {      
      this.globalMode = !!data?.isGlobal;      
    });

    this.route.paramMap.subscribe(async params => {
      this.moduleId = Number(params.get('moduleId'));
      this.mergeFunctionId = Number(params.get('mergeFunctionId'));
      this.templateId = Number(params.get('templateId'));

      const modules = await this.getModulesCmdlet.execute(false);
      this.moduleName = modules.find(x => x.id == this.moduleId).name;

      this.mergeFunction = await this.getMergeFunctionCmdlet.execute({id: this.mergeFunctionId, forceRefresh: false});

      this.baseUrl = this.globalMode ? '/global-modules' : '/modules';

      try {
        this.templateVersions = await this.getTemplateFileMetaCmdlet.execute({templateId: this.templateId, forceRefresh: false}); 

        this.editableRaetName = this.templateVersions.raetName;
        this.editableDescription = this.templateVersions.description;
        
        this.translate.get(this.globalMode ? 'pageTitles.global_template_details' : 'pageTitles.template_details', { templateName: this.templateVersions.raetName }).subscribe((title: string) => {
          this.titleService.setTitle(title);
        });
      } catch (error) {
        this.isBusy = false;
        this.isError = true;
        console.error(error);
        this.logger.error('Error getting template versions', error);
        this.showAlert(AlertTypeEnum.warning, this.text_retrieving_data);
      }

      this.breadcrumbs = [
        { label: this.moduleName },
        { label: this.globalMode ? this.text_global_mergefunctions : this.text_mergefunctions, url: [this.baseUrl, this.moduleId, 'details'], params: { mid: this.mergeFunction.id } },
        { label: this.mergeFunction.name },
        { label: this.text_templates, url: [this.baseUrl, this.moduleId, 'merge-functions', this.mergeFunction.id, 'details'], params: { tid: this.templateId } },
        { label: this.templateVersions?.raetName || 'Template' }
      ];
      
      this.imagePath = await this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + this.templateVersions.thumbnail);

      this.isBusy = false;

      super.ngOnInit();
    });
  }

  showAlert(type: AlertTypeEnum, text: string) {
    this.alertService.setAlert({ type, text });
  }

  onThumbnailError() {
    this.imagePath = null;
  }

  onDeleteRowClicked(row: TemplateOvm) {
    throw new Error('Method not implemented.');
  }
  onAddNewRowClicked(arg: any) {
    throw new Error('Method not implemented.');
  }
  onEditRowClicked(row: TemplateOvm) {
    throw new Error('Method not implemented.');
  }
  onViewRowClicked(row: TemplateOvm) {
    throw new Error('Method not implemented.');
  }

  async loadRows(): Promise<TemplateOvm[]> {
    return null;
  }

  get tableConfig(): TableConfig {
    throw new Error('Method not implemented.');
  }

  async setActive($event) {    
    var templateVersionRevertIm = new TemplateVersionRevertIm();
    templateVersionRevertIm.templateId = $event.sourceFileId;
    templateVersionRevertIm.templateVersion = $event.id;    
    await this.revertTemplateVersionCmdlet.execute(templateVersionRevertIm);    
  }

  onEditemplateClicked($event) {    
    const currentParams = new URLSearchParams(window.location.search);
    const activeParam = currentParams.get('active');
    const baseUrl = this.globalMode ? '/global-modules/' : '/modules/';

    document.location.href = baseUrl + this.moduleId + '/merge-functions/' + this.mergeFunction.id + '/templates/' + this.templateId + '/editor' + (activeParam ? '?active=' + activeParam : '');
  }

  async onDeleteTemplateClicked($event) {    
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        confirmationText: this.text_delete_confirmation,
        buttons: [DialogButtonType.Yes, DialogButtonType.No],
        defaultButton: DialogButtonType.No
      }
    });
    
    dialogRef.afterClosed().subscribe(async result => {
      if (result === DialogButtonType.Yes) {
        try {
          this.actionBusy = true;
          var templateDelm = new TemplateDelm();
          templateDelm.mergeFunctionId = this.mergeFunction.id;
          templateDelm.templateId = this.templateId;
          await this.deleteTemplateCmdlet.execute(templateDelm);
          this.mergFunctionsApiClient.clearTemplatesCache();
          this.mergFunctionsApiClient.clearMergeFunctionsCache();
          this.actionBusy = false;
          this.router.navigate([this.baseUrl, this.moduleId, 'merge-functions', this.mergeFunction.id, 'details'], { queryParamsHandling: 'merge' }); 
        } catch (error) {
          console.error(error);
          this.logger.error('Error deleting template', error);
          this.actionBusy = false;
          this.showAlert(AlertTypeEnum.warning, this.text_error_deleting_template);
        }
      }
    });
  }

  async onSaveTemplateClicked($event) {   

    if (!this.editableRaetName || !this.editableDescription) {
      this.showAlert(AlertTypeEnum.warning, this.text_name_desc_required);
      return;
    }

    try {
      this.actionBusy = true;

      let templateUpdate4Meta = new TemplateUpdate4Meta();
      templateUpdate4Meta.id = this.templateId;
      templateUpdate4Meta.name = this.editableRaetName.length > 50 ? this.editableRaetName.substring(0, 50) : this.editableRaetName;
      templateUpdate4Meta.description = this.editableDescription.length > 250 ? this.editableDescription.substring(0, 250) : this.editableDescription;      
      
      await this.updateTemplateFileMetaCmdlet.execute({model: templateUpdate4Meta, globalMode: this.globalMode});

      this.mergFunctionsApiClient.clearTemplatesCache();
      this.mergFunctionsApiClient.clearMergeFunctionsCache();

      // Update template in memory after successful update
      this.templateVersions.raetName = templateUpdate4Meta.name;
      this.templateVersions.description = templateUpdate4Meta.description;

      this.actionBusy = false;
      this.showAlert(AlertTypeEnum.success, this.text_template_saved);
    } catch (error) {
      console.error(error);
      this.logger.error('Error saving template', error);
      this.actionBusy = false;
      this.showAlert(AlertTypeEnum.warning, this.text_error_saving_template);
    }
  }

  async onExportTemplateClicked(selectedOption: SelectOption) {
    this.actionBusy = true;    
    let req = new DownloadSourceFileReq();
    req.templateId = this.templateId;

    switch (selectedOption.id) {
      case '.docx': {
        req.documentType = 0;
        break;
      }
      case '.doc': {
        req.documentType = 1;
        break;
      }
      case '.bin': {
        req.documentType = 2;
        break;
      }
    }
    
    const fileBlob = await this.downloadATemplateCmdlet.execute(req);
    
    // Now provide the file for download
    const fileName = this.templateVersions.raetName + selectedOption.id;
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    const url = window.URL.createObjectURL(fileBlob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    
    this.actionBusy = false;
  }

  onRedoTemplateClicked($event) {
    this.componentContext.isDiaglogShown = true;
    const config: MatDialogConfig = new MatDialogConfig();
    config.panelClass = 'tm-dialog';
    config.data = {mergeFunctionId: this.mergeFunctionId, templateVersions: this.templateVersions};
    const dialogRef1 = this.dialog.open<TemplateRedoComponent, any, boolean>(TemplateRedoComponent, config);
    const dialogInstance1 = dialogRef1.componentInstance;
    //dialogInstance1.selectedTemplateType = FileTypeEnum.TextBlock;
    dialogRef1.afterClosed().subscribe(async (result) => {
      console.info('Dialog closed. Result:', result);
      if (result) {
        this.templatesApiClient.clearTemplateCache();
        await this.reloadView();
      }
    });
  }

  onTemplateVersionClicked($event) {
    const baseUrl = this.globalMode ? '/global-modules' : '/modules';
    this.router.navigate([baseUrl, this.moduleId, 'merge-functions', this.mergeFunction.id, 'templates', this.templateId, 'versions'], { queryParamsHandling: 'merge' });
  }
}
