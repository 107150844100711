<div class="dialog-layout">
  <div class="right-panel form">
    <form [formGroup]="formGroup" disabled="success">

      <div class="close-button">
        <button class="dialog-close-button" type="button" title="Sluiten" (click)="onCloseClicked($event)">x</button>
      </div>

      <div class="wizard">
        <div class="step">

          <div class="title">
            <p>{{ 'labels.upload_local_template' | translate }}</p>
          </div>

          <div class="body">
            <div class="form">
              <div class="form-row">
                <label for="file-content">{{ 'labels.selected_file' | translate }}</label>
                <div class="file-form-control">
                  <span>{{fileName}}</span>
                  <button type="button" (click)="onUploadFileClicked($event)">{{ 'buttons.open' | translate }}</button>
                  <input id="select-file" style="display: none;" type="file" accept=".doc,.docx,.bin"
                    (change)="onFileChange($event)">
                </div>
              </div>
            </div>
          </div>

          <div class="footer">
            <button type="button" class="optional-button" [disabled]="isLoading"
              (click)='onCancelClicked($event)'>{{ 'buttons.cancel' | translate }}</button>
            <button type="button" class="primary-button" [disabled]="isLoading"
              (click)="onNextClicked($event)">{{ 'buttons.create' | translate }}</button>
          </div>

        </div>
      </div>
    </form>
  </div>
</div>