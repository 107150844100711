import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss']
})
export class SearchFieldComponent implements OnInit {
  
  constructor() { }

  ngOnInit(): void { }

  @Input()
  searchText: string;

  @Output()
  readonly textChanged: EventEmitter<string> = new EventEmitter<string>();

  onSearchTextChanged() {
    // Limit the search text to max 20 chars
    if (this.searchText && this.searchText.length > 20) {
      this.searchText = this.searchText.substring(0, 20);
    }
    
    this.textChanged.emit(this.searchText);
  }

  onSearchTextCleared() {
    this.searchText = null;
    this.onSearchTextChanged();
  }
}
