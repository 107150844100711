import { Injectable } from '@angular/core';
import { CmdletBase } from 'src/app/common/services/CmdletBase';
import { JobService } from 'src/app/common/services/JobService';
import { ComponentService } from 'src/app/services/ComponentService';
import { CreateTemplateJob } from '../jobs/CreateTemplateJob';
import { TemplateEditorFileModel } from 'src/app/base-templates/types/helpers/TemplateEditorFileModel';

@Injectable({ providedIn: 'root' })
export default class CreateTemplateCmdlet extends CmdletBase<{model: TemplateEditorFileModel, globalMode: boolean}, any> {

  constructor(private jobService: JobService,
    componentService: ComponentService,
    private createTemplateJob: CreateTemplateJob) {
    super(componentService);
  }

  async executeImpl(arg: {model: TemplateEditorFileModel, globalMode: boolean}): Promise<any> {
    let id = await this.jobService.runJob<CreateTemplateJob, {model: TemplateEditorFileModel, globalMode: boolean}, any>(this.createTemplateJob, arg);
    return id;
  }
}
