import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { Router, ActivatedRoute } from '@angular/router';
import { OverviewComponentBase } from 'src/app/common/components/OverviewComponentBase';
import { TableService, TableConfig } from 'src/app/common/services/TableService';
import { GetModulesCmdlet } from 'src/app/modules/cmdlets/GetModulesCmdlet';
import { ComponentService } from 'src/app/services/ComponentService';
import { MergeFunctionOvm } from 'src/app/types/dataModels/MergeFunctionOvm';
import { GetMergeFunctionsCmdlet } from '../../cmdlets/GetMergeFunctionsCmdlet';
import { PaginatorComponent } from 'src/app/common/components/paginator/paginator.component';
import { AlertService } from 'src/app/common/services/AlertService';
import { AlertTypeEnum } from 'src/app/common/types/enums/alertType.enum';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { AppInsightsLoggerService } from 'src/app/services/logging.service';
import { Title } from '@angular/platform-browser';
import { ViewportScroller } from '@angular/common';
import { MatRow } from '@angular/material/table';
import { ErrorCodes } from 'src/app/common/types/enums/error-codes.enum';

@Component({
  selector: 'app-merge-function-overview',
  templateUrl: './merge-function-overview.component.html',
  styleUrls: ['./merge-function-overview.component.scss']
})
export class MergeFunctionOverviewComponent extends OverviewComponentBase<MergeFunctionOvm> implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,    
    private translate: TranslateService,
    private titleService: Title,
    componentService: ComponentService,
    private getModulesCmdlet: GetModulesCmdlet,
    private getMergeFunctionsCmdlet: GetMergeFunctionsCmdlet,    
    private viewportScroller: ViewportScroller,
    private alertService: AlertService,
    private logger: AppInsightsLoggerService,
    tableService: TableService<MergeFunctionOvm>) {
    super(tableService, componentService)
  }

  toggle = true;
  toggleStepper = false;
  showMergeFunctions = false;
  helpPageVisibility = false;
  displayedColumns: string[] = ["name", "description", "numberActiveTemplates"];
  searchColumns: string[] = ["name", "description"];    
  mergeFunctions: MergeFunctionOvm[];
  moduleId: number;
  moduleName: string;
  showOnlyActive = false;
  isLoaded = false;  
  moduleChosen: boolean = false;
  text_error_retrieving_data: string;
  dataError: boolean = false;
  refreshTrigger = new Subject<void>();
  refreshDoneTrigger = new Subject<void>();
  forceRefresh: boolean = false;
  globalMode: boolean = false;
  rowIdToHighlight: number;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(PaginatorComponent, { static: false }) paginator!: PaginatorComponent;
  @ViewChildren(MatRow, { read: ElementRef }) tableRowsRef: QueryList<ElementRef>;

  protected get tableRows(): QueryList<ElementRef> {
    return this.tableRowsRef;
  }

  async ngOnInit() {
    this.sort.active = "name";
    this.sort.direction = "asc";
    this.sort.disableClear = true;

    this.sort.sortChange.subscribe(() => {      
      this.refreshView();
    });

    this.translate.get([
      'errors.retrieving_data'
    ]).subscribe(translations => {      
      this.text_error_retrieving_data = translations['errors.retrieving_data'];      
    });

    this.route.data.subscribe((data) => {      
      this.globalMode = !!data?.isGlobal;      
    });

    this.route.paramMap.subscribe(async params => {
      this.isLoaded = false;

      const moduleId = Number(params.get('moduleId'));

      if (isNaN(moduleId)) {
        this.moduleChosen = false;
      } else {
        this.moduleId = moduleId;
        this.moduleChosen = true;
      }

      this.route.queryParams.subscribe(params => {
        this.showOnlyActive = params['active'] === 'true';
      });

      await super.ngOnInit();
  
      this.route.queryParamMap.subscribe(params => {
        
        const sortColumn = params.get('msc');
        const sortDirection = params.get('msd');
        const searchText = params.get('mst');
        const mergeFunctionId = params.get('mid');
  
        if (sortColumn && sortDirection) {
          this.sort.active = sortColumn;
          this.sort.direction = sortDirection as 'asc' | 'desc';
          this.sort.sortChange.emit({ active: sortColumn, direction: this.sort.direction });
        }
    
        if (searchText) {
          this.searchText = searchText;
        }
  
        if (mergeFunctionId) {        
            this.highlightRow(Number(mergeFunctionId));
            this.rowIdToHighlight = Number(mergeFunctionId);        
        }
      });
    });
  }

  onPageIndexChanged(index: number) {    
    this.pageIndex = index;
    this.refreshView();
     // Scroll the body to top
     this.viewportScroller.scrollToPosition([0, 0]);
  }

  onshowOnlyActiveChanged(event) {    
    const filteredRows = this.filterRows(this.mergeFunctions, this.showOnlyActive);    
    this.updateRows(filteredRows);
    this.resetPaginator();
  }

  private filterRows(mergeFunctions: MergeFunctionOvm[], showOnlyActive: boolean): MergeFunctionOvm[] {
    if (showOnlyActive == true && mergeFunctions) {
      return mergeFunctions.filter(x => x.numberActiveTemplates != 0)      
    } else {
      return mergeFunctions;
    }
  }

  async loadRows(): Promise<MergeFunctionOvm[]> { 
   
    if (!this.moduleId) {
      return;
    }

    // Get details of active module
    try {
      const modules = await this.getModulesCmdlet.execute(false);

      this.moduleName = modules.find(x => x.id == this.moduleId).name;
      // Normal mode and global mode show the same list of mergfunctions (hence global: false)
      this.mergeFunctions = await this.getMergeFunctionsCmdlet.execute({moduleId: this.moduleId, global: false, forceRefresh: this.forceRefresh});

      this.translate.get(this.globalMode ? 'pageTitles.global_merge_function_overview' : 'pageTitles.merge_function_overview', { moduleName: this.moduleName }).subscribe((title: string) => {
        this.titleService.setTitle(title);
      });

      this.isLoaded = true;
    } catch (error) {
      this.isLoaded = true;
      this.dataError = true;
      console.error('Error while loading merge functions:', error);
      this.logger.error('Error while loading merge functions', error);
      this.showAlert(AlertTypeEnum.warning, this.text_error_retrieving_data);        
      return;
    }

    return this.filterRows(this.mergeFunctions, this.showOnlyActive);
  }

  navigateToBaseTemplates() {
    this.router.navigate(['/base-templates', 'details']);
  }

  onRowMenuButtonClicked(cmd: string, mergeFunction: MergeFunctionOvm) {
    const baseUrl = this.globalMode ? '/global-modules' : '/modules';
    
    this.router.navigate([baseUrl, this.moduleId, 'merge-functions', mergeFunction.id, 'details'], {queryParams: this.getQueryParams()});
  }

  onDeleteRowClicked(row: MergeFunctionOvm) {
    throw new Error('Method not implemented.');
  }

  onAddNewRowClicked(mode: string) {
    throw new Error('Method not implemented.');
  }

  onEditRowClicked(row: MergeFunctionOvm) {
    throw new Error('Method not implemented.');
  }

  onViewRowClicked(row: MergeFunctionOvm) {
    throw new Error('Method not implemented.');
  }

  onSelectRowClicked(row: MergeFunctionOvm) {
    this.dataPage.selectRow(row);

    const baseUrl = this.globalMode ? '/global-modules' : '/modules';
    
    this.router.navigate([baseUrl, this.moduleId, 'merge-functions', row.id, 'details'], {queryParams: this.getQueryParams()});    
  }

  async onRefreshClicked() {
    this.isLoaded = false;
    this.forceRefresh = true;
    await this.reloadView();
    this.forceRefresh = false;
    this.refreshTrigger.next();    
    this.refreshDoneTrigger.next();
  }

  ngOnDestroy(): void {
    this.refreshTrigger.unsubscribe();
    this.refreshDoneTrigger.unsubscribe();
  }

  get tableConfig(): TableConfig {
    let config: TableConfig = {
      filterConfig: {
        columns: this.searchColumns,
        searchText: this.searchText,
      },
      sortConfig: {
        sortColumn: this.sort.active,
        sortDirection: this.sort.direction,
      },
      pageConfig: {
        pageIndex: this.pageIndex,
      },
    };
    return config;
  }

  private resetPaginator(): void {
    if (this.paginator) {
      this.paginator.setPageIndex(0); 
    }
  }

  private showAlert(type: AlertTypeEnum, text: string) {
    this.alertService.setAlert({ type, text });
  }

  private getQueryParams() {
    const result = {
      msc: this.sort.active,
      msd: this.sort.direction,
      mst: this.searchText
    };  

    if (this.showOnlyActive) {
      result['active'] = 'true';
    }

    return result;
  }
}
