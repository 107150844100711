import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { MenuItem, SubheaderMenuItem } from '../../types/models/menu-item.model';

@Component({
  selector: 'app-menu-dropdown',  
  templateUrl: './menu-dropdown.component.html',
  styleUrls: ['./menu-dropdown.component.scss']
})
export class MenuDropdownComponent {
  @Input() menuItem!: SubheaderMenuItem;
  @Input() activeItemId: string | null = null;
  @Output() menuItemSelected = new EventEmitter<MenuItem>();

  constructor(private elementRef: ElementRef) {}

  toggleDropdown() {
    if (this.menuItem.subItems && this.menuItem.subItems.length > 0) {
      this.menuItem.isDropdownOpen = !this.menuItem.isDropdownOpen;
    } else {
      this.menuItemSelected.emit(this.menuItem);
    }
  }

  onSubItemSelected(subItem: MenuItem, event: Event) {
    event.stopPropagation();
    this.menuItem.isDropdownOpen = false; 
    this.menuItemSelected.emit(subItem);
  }

  isSubItemActive(): boolean {
    return this.menuItem.subItems?.some(subItem => subItem.id === this.activeItemId) || false;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (this.menuItem.isDropdownOpen && !this.elementRef.nativeElement.contains(event.target)) {
      this.menuItem.isDropdownOpen = false;
    }
  }
}