import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ComponentService } from 'src/app/services/ComponentService';
import { FileTypeEnum } from '../../types/enums/FileTypeEnum';
import { DialogComponentBase } from '../DialogComponentBase';
import CreateBaseTemplateCmdlet from 'src/app/base-templates/cmdlets/CreateBaseTemplateCmdlet';
import { TemplateEditorFileModel } from 'src/app/base-templates/types/helpers/TemplateEditorFileModel';
import { BaseTemplateOdm } from 'src/app/types/dataModels/BaseTemplateOdm';
import { MergeFunctionOvm } from 'src/app/types/dataModels/MergeFunctionOvm';
import { TemplateOvm } from 'src/app/types/viewModels/TemplateOvm';
import { BusyService } from '../../services/BusyService';
import { AlertService } from 'src/app/services/AlertService';

@Component({
  selector: 'app-upload-template',
  templateUrl: './upload-template.component.html',
  styleUrls: ['./upload-template.component.scss']
})
export class UploadBaseTemplateComponent extends DialogComponentBase<UploadBaseTemplateComponent, boolean> implements OnInit {
  formGroup: UntypedFormGroup;
  templateFile: Blob;
  selectedTemplateIdToCopy: number;
  templates: TemplateOvm[];
  defaultTemplates: TemplateOvm[];
  selectedTemplateType: FileTypeEnum;
  baseTemlateCount: number;
  newBaseTemlateNumber: number;
  mergeFunction: MergeFunctionOvm;
  selectedTemplateId: number;
  selectedTemplate: BaseTemplateOdm;
  selectedFileType: FileTypeEnum;
  selectedFileName: string;
  fileExtension: string;
  currentStepId: string = "start-step";
  isBusy: boolean;
  alertToBeDisplayed: boolean = false;
  config: any = {
    'use-local-template': ['start-step', 'select-file-step', 'name-description-step'],
    'create-empty-template': ['start-step', 'name-description-step'],
  };

  constructor(private formBuilder: UntypedFormBuilder,
    componentService: ComponentService,    
    private cd: ChangeDetectorRef,
    private alertService: AlertService,
    private busyService: BusyService,
    dialogRef: MatDialogRef<UploadBaseTemplateComponent, boolean>,    
    private createBaseTemplateCmdlet: CreateBaseTemplateCmdlet    
  ) {
    super(componentService, dialogRef);
  }

  // @ViewChild('search') search: ElementRef;

  async ngOnInit() {

    this.alertService.hasMessage$.subscribe((hasMessage) => {
      this.alertToBeDisplayed = hasMessage;
    });

    super.ngOnInit();
    this.newBaseTemlateNumber = this.baseTemlateCount + 1;

    this.formGroup = this.formBuilder.group({      
      name: ['', [Validators.required, Validators.maxLength(50)]],      
      selectedTemplate: ['', []],
      wizardType: ['use-local-template'],
    });    
    this.isLoaded = true;
  }

  async onFormSubmitted() {
    if (this.baseTemlateCount > 10) {
      //alert('Base template count is too large')
    }

    else {
      switch (this.wizardType) {

        case 'use-local-template': {

          try {
            this.isBusy = true;
            this.busyService.showBusy();

            let templateEditorFileModel: TemplateEditorFileModel = await this.getModel();
            templateEditorFileModel.name = this.f.name.value;
            templateEditorFileModel.documentType = this.fileExtension;
            await this.createBaseTemplateCmdlet.execute(templateEditorFileModel);
          } finally {
            this.isBusy = false;
            this.busyService.hideBusy();
          }

          break;
        }

        case 'create-empty-template': {

          try {
            this.isBusy = true;
            this.busyService.showBusy();

            let templateEditorFileModel = new TemplateEditorFileModel();
            templateEditorFileModel.name = this.f.name.value;
            templateEditorFileModel.content = '';
            await this.createBaseTemplateCmdlet.execute(templateEditorFileModel);
          } finally {
            this.isBusy = false;
            this.busyService.hideBusy();
          }
          
          break;
        }
      }
    }

    //await this.routerService.baseTemplateDone();
    this.result = true;
    this.close();
    return;
  }

  onNextClicked(event) {
    if (!this.validateStep(this.currentStepId)) {
      return;
    }

    const currentStepId = this.currentStepId;
    const wizardConfig: string[] = this.config[this.wizardType];
    const currentIndex = wizardConfig.findIndex(x => x == currentStepId);
    const isLastStep = currentIndex == wizardConfig.length - 1;

    if (!isLastStep) {
      this.currentStepId = wizardConfig[currentIndex + 1];
      event.preventDefault();
    }
    else {
      this.onFormSubmitted();
    }

  }

  onPrevClicked(event) {
    const currentStepId = this.currentStepId;
    const wizardConfig: string[] = this.config[this.wizardType];
    const currentIndex = wizardConfig.findIndex(x => x == currentStepId);
    this.currentStepId = wizardConfig[currentIndex - 1];
    event.preventDefault();
  }

  onTemplateLocationChanged() {
    this.f.selectedFolder.setValue(undefined);
  }

  onTemplateChange($event) {    
    this.selectedTemplateIdToCopy = $event.target.value;    
  }

  onFileChange(event) {

    let reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.selectedFileName = event.target.files[0].name;
      let extension = event.target.files[0].name;
      this.fileExtension = extension.split('.').pop();
      
      // Set the name field of the form tot the name of the file without extension
      this.f.name.setValue(extension.substring(0, extension.lastIndexOf('.')));

      reader.readAsBinaryString(file);
      reader.onload = () => {

        this.templateFile = file;
        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();
      };
    }
  }

  onUploadFileClicked(event) {
    document.getElementById("select-file").click();    
    event.preventDefault();
  }

  get f() { return this.formGroup.controls; }

  get wizardType(): string {
    return this.f.wizardType.value;
  }

  private async getModel(): Promise<TemplateEditorFileModel> {

    const model = new TemplateEditorFileModel();
    model.name = this.f.name.value;
    //model.fileType = this.selectedTemplateType;

    if (this.templateFile) {
      model.content = await this.blobToBase64Async(this.templateFile);
    }

    return model;
  }

  async blobToBase64Async(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onerror = (e) => reject(fileReader.error);
      fileReader.onloadend = (e) => {
        const dataUrl = fileReader.result as string;
        // remove "data:mime/type;base64," prefix from data url
        const base64 = dataUrl.substring(dataUrl.indexOf(',') + 1);
        resolve(base64);
      };
      fileReader.readAsDataURL(blob);
    });
  }

  private validateStep(stepId: string): boolean {

    if (stepId == 'start-step') {
      // clear this.f.name.value
      this.f.name.setValue('');
      this.selectedFileName = '';
      this.templateFile = null;
    }

    if (stepId == 'name-description-step') {
      return this.validateGenerelInfoStep();
    }

    if (stepId == 'select-file-step') {
      // Make sure a file is selected
      return !!this.selectedFileName;
    }

    return true;
  }

  validateSelectTemplateStep(): boolean {

    let result = true;

    if (!this.f.selectedTemplate.value) {
      this.f.selectedTemplate.setErrors({ required: true });
      result = false;
    }

    return result;
  }

  validateGenerelInfoStep(): boolean {

    if (this.formGroup.valid) {
      return true;
    }

    this.formGroup.markAllAsTouched();

    return false;
  }
}
