<div class="right-content-container">
  <div *ngIf="isBusy">
    <ngx-skeleton-loader appearance="" count="1" [theme]="{height: '24px'}"></ngx-skeleton-loader>
  </div>
  <app-bread-crumbs *ngIf="!isBusy" [breadcrumbs]="breadcrumbs"></app-bread-crumbs>

  <div class="page-toolbar">
    <span *ngIf="templateVersions && !templateVersions.isGlobal">{{ 'headers.template' | translate }}</span>
    <span *ngIf="templateVersions && templateVersions.isGlobal">{{ 'headers.global_template' | translate }}</span>
  </div>
  <alert></alert>

  <div *ngIf="isBusy" class="table-header flex-direction-column">
    <ngx-skeleton-loader appearance="line" count="4" [theme]="{height: '24px'}"></ngx-skeleton-loader>
  </div>
  
  <div *ngIf="!isBusy && templateVersions" class="table-header flex-direction-column">    
    <p class="panel-header">{{ 'headers.template_details' | translate }}</p>
    <table class="mergeFunctionTable">
      <tbody>
        <tr>
          <td class="label"><span>{{ 'labels.name' | translate }}:</span></td>
          <td class="value">            
            <input [(ngModel)]="editableRaetName" class="input" id="name" [readonly]="templateVersions.isGlobal && !globalMode" maxlength="50">
          </td>
        </tr>
        <tr>
          <td class="label"><span>{{ 'labels.description' | translate }}:</span></td>
          <td class="value">
            <input [(ngModel)]="editableDescription" class="input" id="description" [readonly]="templateVersions.isGlobal && !globalMode" maxlength="250">
          </td>
        </tr>
        <tr>
          <td class="label"><span>{{ 'labels.status' | translate }}:</span></td>
          <td class="value">
            <p *ngIf="templateVersions.isDisabled" class="description">
              {{ 'labels.inactive' | translate }}
            </p>
            <p *ngIf="!templateVersions.isDisabled" class="description">
              {{ 'labels.active' | translate }}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="flex-align-end">
      <button *ngIf="!templateVersions.isGlobal || globalMode" (click)="onSaveTemplateClicked($event)" [disabled]="actionBusy" class="editButton3">
        {{ 'buttons.save' | translate }}
      </button>
    </div>    
  </div>

  <div *ngIf="isBusy" class="table-header flex-direction-column">
    <div class="skeleton-wrapper">
      
      <ngx-skeleton-loader 
        count="1" 
        appearance="rectangle" 
        [theme]="{ height: '300px', width: '200px', 'margin-right': '16px' }">
      </ngx-skeleton-loader>    
      
      <div class="skeleton-lines">
        <ngx-skeleton-loader appearance="" count="4" [theme]="{height: '24px'}"></ngx-skeleton-loader>
      </div>
    </div>
  </div>

  <div *ngIf="!isBusy" class="table-header">
    <div>
      <p class="panel-header">{{'headers.template' | translate}}</p>  
      <div class="thumbnail" [ngClass]="{'error': !imagePath}">
        <img *ngIf="imagePath" [src]="imagePath" (error)="onThumbnailError()" alt="Thumbnail image">
        <span *ngIf="!imagePath" class="error-text">{{ 'errors.no_thumbnail' | translate }}</span>
      </div>    
    </div>
    <div class="templateDetails" *ngIf="templateVersions && !templateVersions.isGlobal || globalMode">
      <ul>
        <li>
          <span class="vismaicon vismaicon-dynamic vismaicon-sm vismaicon-edit vertical-middle" aria-hidden="true"></span>
          <button (click)="onEditemplateClicked($event)" [disabled]="actionBusy" class="templateDetailsBtn">{{'buttons.edit' | translate}}</button>
        </li>
        <li>
          <span class="vismaicon vismaicon-dynamic vismaicon-sm vismaicon-refresh vertical-middle" aria-hidden="true"></span>
          <button (click)="onRedoTemplateClicked($event)" [disabled]="actionBusy" class="templateDetailsBtn">{{'buttons.remake' | translate}}</button>
        </li>
        <li >
          <span class="vismaicon vismaicon-dynamic vismaicon-sm vismaicon-history vertical-middle" aria-hidden="true"></span>
          <button (click)="onTemplateVersionClicked($event)" [disabled]="actionBusy" class="templateDetailsBtn">{{'buttons.previous_versions' | translate}}</button>
        </li>
        <li>
          <span class="vismaicon vismaicon-dynamic vismaicon-sm vismaicon-delete vertical-middle" aria-hidden="true"></span>
          <button (click)="onDeleteTemplateClicked($event)" [disabled]="actionBusy" class="templateDetailsBtn">{{'buttons.delete' | translate}}</button>
        </li>
        <li class="pt-16">
          <app-dropdown-button [title]="'buttons.export' | translate" [disabled]="actionBusy" [items]="exportItems"
            (clicked)="onExportTemplateClicked($event)">
          </app-dropdown-button>
        </li>
      </ul>
    </div>
  </div>
</div>