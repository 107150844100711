import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { DeleteATemplateCmdlet } from 'src/app/base-templates/cmdlets/DeleteATemplateCmdlet';
import { DownloadATemplateCmdlet } from 'src/app/base-templates/cmdlets/DownloadATemplateCmdlet';
import { GetBaseTemplateMetaCmdlet } from 'src/app/base-templates/cmdlets/GetBaseTemplateMetaCmdlet';
import { GetBaseTemplatesCmdlet } from 'src/app/base-templates/cmdlets/GetBaseTemplatesCmdlet';
import { UpdateATemplateCmdlet4Meta } from 'src/app/base-templates/cmdlets/UpdateATemplateCmdlet4Meta';
import { DownloadSourceFileReq } from 'src/app/base-templates/types/helpers/DownloadSourceFileReq';
import { OverviewComponentBase } from 'src/app/common/components/OverviewComponentBase';
import { UploadBaseTemplateComponent } from 'src/app/common/components/upload-template/upload-template.component';
import { JobService } from 'src/app/common/services/JobService';
import { ProcessingStorage } from 'src/app/common/services/ProcessingStorage';
import { TableService, TableConfig } from 'src/app/common/services/TableService';
import { FileTypeEnum } from 'src/app/common/types/enums/FileTypeEnum';
import { ASourceFileUm4Meta } from 'src/app/common/types/helpers/ASourceFileUm4Meta';
import { ComponentContext } from 'src/app/services/ComponentContext';
import { ComponentService } from 'src/app/services/ComponentService';
import { GetTemplateFileMetaByIdJob } from 'src/app/templates/jobs/GetTemplateFileMetaByIdJob';
import { RaetTemplateOm } from '../../types/RaetTemplateOm';
import { GetRaetTemplateMetaCmdlet } from '../../cmdlets/GetRaetTemplateMetaCmdlet';
import { UploadReplaceTemplateComponent } from 'src/app/common/components/upload-replace-template/upload-replace-template.component';
import { DomSanitizer } from '@angular/platform-browser';
import { SelectOption } from 'src/app/common/types/helpers/SelectOption';

@Component({
  selector: 'app-raet-template-details',
  templateUrl: './raet-template-details.component.html',
  styleUrl: './raet-template-details.component.scss'
})
export class RaetTemplateDetailsComponent extends OverviewComponentBase<RaetTemplateOm> implements OnInit {


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    componentService: ComponentService,
    private downloadATemplateCmdlet: DownloadATemplateCmdlet,
    //private getraetTemplatesCmdlet: GetRaetTemplatesCmdlet,
    private getRaetTemplateMetaCmdlet: GetRaetTemplateMetaCmdlet,
    private localStorage: ProcessingStorage,
    private deleteATemplateCmdlet: DeleteATemplateCmdlet,
    private updateTemplateMetaCmdlet: UpdateATemplateCmdlet4Meta,
    public componentContext: ComponentContext,
    private dialog: MatDialog,
    private jobService: JobService,
    private job: GetTemplateFileMetaByIdJob,
    private _sanitizer: DomSanitizer,
    tableService: TableService<RaetTemplateOm>) {
    super(tableService, componentService)
  }

  selectedRaetTemplateId: number;
  raetTemplate: RaetTemplateOm;
  imagePath: any;
  exportItems: SelectOption[] = [
    { id: '.docx', text: 'Export to docx' },
    { id: '.doc', text: 'Export to doc' },
    { id: '.bin', text: 'Export to bin' },
  ];

  @ViewChildren('tableRow') tableRowsRef: QueryList<ElementRef>;

  protected get tableRows(): QueryList<ElementRef> {
    return this.tableRowsRef;
  }

  async ngOnInit() {

    this.selectedRaetTemplateId = this.localStorage.raetTemplate.id;
    console.log(this.localStorage.raetTemplate.id)
    this.raetTemplate = await this.getRaetTemplateMetaCmdlet.execute(this.selectedRaetTemplateId);
    this.raetTemplate.isActive = this.localStorage.raetTemplate.isActive;
    console.log(this.raetTemplate)
    if (this.raetTemplate.thumbnail) {
      this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,'
        + this.raetTemplate.thumbnail);
    }
    super.ngOnInit();
  }

  onEditemplateClicked($event) {
    this.router
      .navigate(['/raet-template-editor'], { relativeTo: this.route })
      .then(() => { window.location.reload(); });

      // Force reload to make sure the editor is loaded correctly
      //document.location.href = '/raet-template-editor/' + this.selectedBaseTemplateId + '/editor';    
  }

  onDeleteTemplateClicked($event) {
    console.log(this.localStorage.raetTemplate)
    this.deleteATemplateCmdlet.execute(this.localStorage.raetTemplate.id);
    this.router.navigate(['/raet-templates'], { relativeTo: this.route });
    //alert('Raet template deleted!')
  }

  async onExportTemplateClicked(selectedOption: SelectOption) {
    
    let req = new DownloadSourceFileReq();
    req.templateId = this.localStorage.raetTemplate.id;

    switch (selectedOption.id) {
      case '.docx': {
        req.documentType = 0;
        break;
      }
      case '.doc': {
        req.documentType = 1;
        break;
      }
      case '.bin': {
        req.documentType = 2;
        break;
      }
    }

    await this.downloadATemplateCmdlet.execute(req);
  }

  async onSaveTemplateClicked($event) {
    let model = this.getModel();
    await this.updateTemplateMetaCmdlet.execute(model);
    //alert('Base template saved!')
  }

  private getModel() {
    let model = new ASourceFileUm4Meta();
    //model.name = this.f.name.value;
    //model.id = this.localStorage.raetTemplate.id;
    //model.name = this.raetTemplate.name;
    // model.description = this.f.fileDescription.value;
    // model.fileType = this.selectedFileType;
    return model;
  }

  onDeleteRowClicked(row: RaetTemplateOm) {
    //throw new Error('Method not implemented.');
  }
  onAddNewRowClicked(arg: any) {
    this.componentContext.isDiaglogShown = true;

    // switch (mode) {
    //   case "wizard":
    //     this.addNewTemplateViaWizard();
    //     break;

    //case "upload":
    this.addNewTemplateViaUpload();
  }

  addNewTemplateViaUpload() {
    this.componentContext.isDiaglogShown = true;
    const config: MatDialogConfig = new MatDialogConfig();
    config.panelClass = 'tm-dialog';
    const dialogRef1 = this.dialog.open<UploadReplaceTemplateComponent, any, boolean>(UploadReplaceTemplateComponent, config);
    const dialogInstance2 = dialogRef1.componentInstance;
    dialogInstance2.templateIdToReplace = this.localStorage.raetTemplate.id;
    dialogRef1.afterClosed().subscribe(async (result) => {
      this.componentContext.isDiaglogShown = false;

      if (result) {
        await this.reloadView();
      }
    });
  }

  onOtherTemplateClicked($event) {
    this.router.navigate(['/raet-templates'], { relativeTo: this.route });
  }

  onEditRowClicked(row: RaetTemplateOm) {
  }
  onViewRowClicked(row: RaetTemplateOm) {
  }
  loadRows(): Promise<RaetTemplateOm[]> {
    return new RaetTemplateOm[0];
  }
  get tableConfig(): TableConfig {
    return new TableConfig()
  }

  onRedoTemplateClicked($event) {
    this.router.navigate(['/empty-raet-template-editor'], { relativeTo: this.route }).then(() => { window.location.reload(); });
  }

  redirect(num: number) {
    switch (num) {
      case 1: {
        this.router.navigate(['/raet-templates'], { relativeTo: this.route });
        break;
      }
    }
  }
}

