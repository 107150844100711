import { Component, ElementRef, HostListener, Input } from '@angular/core';

@Component({
  selector: 'app-drop-down-icon',  
  templateUrl: './drop-down-icon.component.html',
  styleUrl: './drop-down-icon.component.scss'
})
export class DropDownIconComponent {
  @Input() iconUrl: string = '';
  isOpen: boolean = false;
  isRightAligned: boolean = false;

  constructor(private elementRef: ElementRef) {}

  toggleDropdown() {
    this.isOpen = !this.isOpen;

    if (this.isOpen) {
      const rect = this.elementRef.nativeElement.getBoundingClientRect();
      const viewportWidth = window.innerWidth;

      // Check if the dropdown overflows to the right
      this.isRightAligned = rect.right + 200 > viewportWidth;
    }
  }

  @HostListener('document:click', ['$event'])
  closeDropdown(event: MouseEvent) {
    if (this.isOpen && !this.elementRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }
}