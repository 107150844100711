<div class="menu-dropdown-container">
    <div
      class="menu-item"
      (click)="toggleDropdown()"
      [class.active]="menuItem.id === activeItemId || isSubItemActive()"
    >
      <span class="title">{{ menuItem.displayName }}</span>
      <span
        *ngIf="menuItem.subItems && menuItem.subItems.length > 0"
        class="caret-icon"
        [class.open]="menuItem.isDropdownOpen"
      >
        <svg width="10" height="6" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1 L5 5 L9 1" stroke="currentColor" stroke-width="2" fill="none" />
        </svg>
      </span>
    </div>
    <div class="sub-item-dropdown" *ngIf="menuItem.isDropdownOpen">
      <div
        *ngFor="let subItem of menuItem.subItems"
        class="my-menu-item"
        [class.active]="subItem.id === activeItemId"
        (click)="onSubItemSelected(subItem, $event)"
      >
        {{ subItem.displayName }}
      </div>
    </div>
  </div>
  