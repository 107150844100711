import { Injectable } from "@angular/core";
import { ComponentService } from "src/app/services/ComponentService";
import { CmdletBase } from "src/app/common/services/CmdletBase";
import { JobService } from "src/app/common/services/JobService";
import { VoidResult } from "src/app/types/helpers/VoidResult";
import { MergeFunctionUm4TemplateReq } from "../types/MergeFunctionUm4TemplateReq";
import { UpdateMergeFunctionTemplateByIdJob } from "../jobs/UpdateMergeFunctionTemplateByIdJob";

@Injectable({ providedIn: 'root' })
export class UpdateMergeFunctionTemplateByIdCmdlet extends CmdletBase<{model: MergeFunctionUm4TemplateReq, globalMode: boolean, globalTemplate: boolean}, VoidResult> {

  constructor(private jobService: JobService, private job: UpdateMergeFunctionTemplateByIdJob, componentService: ComponentService) {
    super(componentService);
  }

  protected async executeImpl(arg: {model: MergeFunctionUm4TemplateReq, globalMode: boolean, globalTemplate: boolean}): Promise<VoidResult> {
    await this.jobService.runJob<UpdateMergeFunctionTemplateByIdJob, {model: MergeFunctionUm4TemplateReq, globalMode: boolean, globalTemplate: boolean}, VoidResult>(this.job, arg);
    return new VoidResult();
  }
}
