import { Injectable } from '@angular/core';
import { CmdletBase } from 'src/app/common/services/CmdletBase';
import { JobService } from 'src/app/common/services/JobService';
import { ComponentService } from 'src/app/services/ComponentService';
import { TemplateCopyIm } from '../types/TemplateCopyIm';
import { CopyTemplateJob } from '../jobs/CopyTemplateJob';

@Injectable({ providedIn: 'root' })
export default class CopyTemplateCmdlet extends CmdletBase<{model: TemplateCopyIm, globalMode: boolean}, any> {

  constructor(private jobService: JobService,
    componentService: ComponentService,
    private copyTemplateJob: CopyTemplateJob) {
    super(componentService);
  }

  async executeImpl(arg: {model: TemplateCopyIm, globalMode: boolean}): Promise<any> {
    let result = await this.jobService.runJob<CopyTemplateJob, {model: TemplateCopyIm, globalMode: boolean}, any>(this.copyTemplateJob, arg);
    return result;
  }
}
