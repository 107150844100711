import { Injectable } from '@angular/core';
import { IJob } from 'src/app/authentication/interfaces/IJob';
import { VoidResult } from 'src/app/types/helpers/VoidResult';
import { TemplatesApiClient } from '../api-clients/TemplatesApiClient';
import { TemplateCopyIm } from '../types/TemplateCopyIm';

@Injectable({ providedIn: 'root' })
export class CopyTemplateJob implements IJob<{model: TemplateCopyIm, globalMode: boolean}, VoidResult> {

  constructor(private templatesApiClient: TemplatesApiClient) { }

  async execute(arg: {model: TemplateCopyIm, globalMode: boolean}): Promise<any> {
    let result = await this.templatesApiClient.copyTemplate(arg.model, arg.globalMode);
    return result;
  }
}
