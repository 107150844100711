import { Injectable } from "@angular/core";
import { IJob } from "src/app/authentication/interfaces/IJob";
import { VoidResult } from "src/app/types/helpers/VoidResult";
import { TemplatesApiClient } from "../api-clients/TemplatesApiClient";
import { TemplateUpdate4Meta } from "../types/TemplateUpdate4Meta";

@Injectable({ providedIn: 'root' })
export class UpdateTemplateFileMetaJob implements IJob<{model: TemplateUpdate4Meta, globalMode: boolean}, VoidResult> {

  constructor(private templatesApiClient: TemplatesApiClient) { }

  async execute(arg: {model: TemplateUpdate4Meta, globalMode: boolean}): Promise<VoidResult> {
    await this.templatesApiClient.updateTemplateFileMeta(arg.model, arg.globalMode);
    return VoidResult;
  }
}
