<div class="search-field">

  <mat-icon class="search-button" title="Clear">search</mat-icon>

  <input [(ngModel)]="searchText" (keyup)="onSearchTextChanged()" autocomplete="off" class="searchbox" maxlength="20"
    placeholder="{{'labels.search_placeholder' | translate}}">

  <!-- <button matSuffix (click)="onSearchTextCleared()" *ngIf="searchText" class="clear-button">
  <mat-icon class="clear" title="Clear">close</mat-icon>
</button> -->
</div>