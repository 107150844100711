<div class="dialog-layout" *ngIf="isLoaded">

  <!-- <div class="left-panel">

    <div class="main-text">
      <p>New Base Template</p>
    </div>

  </div> -->

  <div class="right-panel form">

    <form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="onFormSubmitted()" disabled="success">

      <div class="close-button">
        <button class="dialog-close-button" type="button" title="Sluiten" (click)="onCloseClicked($event)">x</button>
      </div>

      <div class="wizard">

        <div class="step" id="start-step" *ngIf="currentStepId == 'start-step'">

          <div class="title">
            <p>{{ 'headers.new_base_template' | translate }}</p>
          </div>

          <div *ngIf="alertToBeDisplayed" class="messages">
            <app-user-messages></app-user-messages>
          </div>

          <div class="body">

            <div class="form">

              <div class="form-row radio-row">
                <label for="use-local-template">&nbsp; {{ 'labels.upload_local_template' | translate }}</label>
                <div class="form-control">
                  <input id="use-local-template" name="wizardType" formControlName="wizardType" type="radio"
                    value="use-local-template">
                </div>
              </div>

              <div class="form-row radio-row">
                <label for="create-empty-template">&nbsp; {{ 'labels.use_empty_template' | translate }}</label>
                <div class="form-control">
                  <input id="create-empty-template" name="wizardType" formControlName="wizardType" type="radio"
                    value="create-empty-template">
                </div>
              </div>

            </div>

          </div>

          <div class="footer">

            <button type="button" class="optional-button" [disabled]="isLoading"
              (click)='onCancelClicked($event)'>{{ 'buttons.cancel' | translate }}</button>

            <button type="button" class="primary-button" [disabled]="isLoading"
              (click)="onNextClicked($event)">{{ 'buttons.next' | translate }}</button>

          </div>

        </div>

        <div class="step" id="select-file-step" *ngIf="currentStepId == 'select-file-step'">

          <div class="title">
            <p>{{ 'labels.template_source' | translate }}</p>
            <!-- <p>Please select file you would like to use as a source for your base template</p> -->
          </div>

          <div *ngIf="alertToBeDisplayed" class="messages">
            <app-user-messages></app-user-messages>
          </div>

          <div class="body">

            <div class="form">

              <div class="form-row">
                <label for="file-content">{{ 'labels.selected_file' | translate }}</label>
                <div class="file-form-control">

                  <span>{{selectedFileName}}</span>
                  <button type="button" (click)="onUploadFileClicked($event)">{{ 'buttons.open' | translate }}</button>

                  <!-- <label for="select-file">Bestand</label> -->
                  <input id="select-file" type="file" accept=".doc,.docx,.bin" (change)="onFileChange($event)">
                </div>
              </div>
            </div>
          </div>

          <div class="footer">

            <button type="button" class="optional-button" [disabled]="isLoading"
              (click)="onPrevClicked($event)">{{ 'buttons.previous' | translate }}</button>

            <button type="button" class="optional-button" [disabled]="isLoading"
              (click)='onCancelClicked($event)'>{{ 'buttons.cancel' | translate }}</button>

            <button type="button" class="primary-button" [disabled]="isLoading || !templateFile"
              (click)="onNextClicked($event)">{{ 'buttons.next' | translate }}</button>

          </div>
        </div>

        <div class="step" id="name-description-step" *ngIf="currentStepId == 'name-description-step'">

          <div class="title">
            <p>{{ 'headers.template_name' | translate }}</p>
          </div>

          <div *ngIf="alertToBeDisplayed" class="messages">
            <app-user-messages></app-user-messages>
          </div>

          <div class="body">

            <div class="form">

              <div class="form-row">
                <label for="name">{{ 'labels.name' | translate }}<span class="required-field-marker">*</span></label>
                <div class="form-control">
                  <input id="name" formControlName="name" type="text" maxlength="50">
                </div>
                <div *ngIf="f.name.errors?.required && (f.name.touched || f.name.dirty)" class="form-field-error-text">
                  {{ 'labels.required_field' | translate }}
                </div>
                <div *ngIf="f.name.errors?.maxlength && (f.name.touched || f.name.dirty)" class="form-field-error-text">
                  {{ 'labels.maxlength_field' | translate:{ max: f.name.errors.maxlength.requiredLength } }}
                </div>
              </div>
            </div>

          </div>

          <div class="footer">

            <button type="button" class="optional-button" [disabled]="isLoading || isBusy"
              (click)="onPrevClicked($event)">{{ 'buttons.previous' | translate }}</button>
            <button type="button" class="optional-button" [disabled]="isLoading || isBusy"
              (click)='onCancelClicked($event)'>{{ 'buttons.cancel' | translate }}</button>
            <button type="button" class="primary-button" [disabled]="isLoading || isBusy"
              (click)="onNextClicked($event)">{{ 'buttons.create' | translate }}</button>

          </div>
        </div>
      </div>
    </form>
  </div>
</div>