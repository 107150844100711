import { Injectable } from "@angular/core";
import { IJob } from "src/app/authentication/interfaces/IJob";
import { MergeFunctionsApiClient } from "../api-clients/MergeFunctionsApiClient";
import { VoidResult } from "src/app/types/helpers/VoidResult";
import { MergeFunctionUm4TemplateReq } from "../types/MergeFunctionUm4TemplateReq";

@Injectable({ providedIn: 'root' })
export class UpdateMergeFunctionTemplateByIdJob implements IJob<{model: MergeFunctionUm4TemplateReq, globalMode: boolean, globalTemplate: boolean}, VoidResult> {

  constructor(private mergeFunctionsApiClient: MergeFunctionsApiClient) { }

  async execute(arg: {model: MergeFunctionUm4TemplateReq, globalMode: boolean, globalTemplate: boolean}): Promise<VoidResult> {

    if (arg.globalMode) {
      await this.mergeFunctionsApiClient.updateMergeFunctionGlobalTemplateById(arg.model.templateId, arg.model.isActive, arg.model.isDefault);
    } else {
      await this.mergeFunctionsApiClient.updateMergeFunctionTemplateById(arg.model, arg.globalTemplate);
    }
    
    return VoidResult;
  }
}
