<header id="navbar" class="my-navbar">
    <div class="navbar-main-content">
        <div class="navbar-title">{{title}}</div>
        <div class="navbar-right">
            <app-drop-down-icon class="dropdown-icon" iconUrl="/assets/images/24_help.svg">
                <li 
                class="my-menu-item" 
                *ngFor="let helpItem of helpItems" 
                (click)="onHelpItemSelected(helpItem)">
                {{ helpItem.displayName }}
              </li>
              </app-drop-down-icon>
            <app-drop-down-icon class="dropdown-icon" iconUrl="/assets/images/24_user.svg">
                <li class="my-menu-item" (click)="logoffClicked()">Uitloggen</li>                
              </app-drop-down-icon>
        </div>
    </div>
    <div class="navbar-sub-content">        
        <div class="name-container"></div>
        <div class="items-container">
            <app-menu-dropdown 
                *ngFor="let item of subHeaderItems" 
                [menuItem]="item" 
                [activeItemId]="activeItemId"
                (menuItemSelected)="onSubMenuItemClicked($event)">
            </app-menu-dropdown>
        </div>
    </div>
</header>