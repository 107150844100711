<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li
      *ngFor="let breadcrumb of breadcrumbs; let last = last"
      class="breadcrumb-item"
      [class.active]="last"
    >
      <a
        *ngIf="breadcrumb.url && !last"
        [routerLink]="breadcrumb.url"
        [queryParams]="breadcrumb.params"
        [queryParamsHandling]="'merge'"
        [title]="breadcrumb.label"
      >
        <div class="breadcrumb-label">{{ breadcrumb.label }}</div>
      </a>
      <span *ngIf="!breadcrumb.url || last" [title]="breadcrumb.label">
        <div class="breadcrumb-label">{{ breadcrumb.label }}</div>
      </span>
      <span *ngIf="!last" class="separator"> &gt;<wbr> </span>
    </li>
  </ol>
</nav>
