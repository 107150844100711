import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { SubheaderMenuItem, Item } from '../../types/models/menu-item.model';

/* 
  This navbar component uses some icons from the assets/images folder:
    24_user.svg
    24_help.svg
*/

@Component({
  selector: 'app-nav-bar',  
  templateUrl: './nav-bar.component.html',
  styleUrl: './nav-bar.component.scss'
})
export class NavBarComponent implements AfterViewInit {
  constructor(private elementRef: ElementRef) {}

  @Input() title: string = '';
  @Input() subHeaderItems: SubheaderMenuItem[] = [];
  @Input() helpItems: Item[] = [];
  @Input() activeItemId: string | null = null;

  @Output() subMenuItemClicked = new EventEmitter<any>();
  @Output() helpMenuItemClicked = new EventEmitter<any>();
  @Output() logOutClicked = new EventEmitter<void>();

  ngAfterViewInit(): void {
    const navbar: HTMLElement = this.elementRef.nativeElement.querySelector('#navbar');
    if (navbar) {
      const resizeObserver = new ResizeObserver(() => {
        const navbarHeight = navbar.offsetHeight;
        document.body.style.marginTop = `${navbarHeight}px`;
      });
      resizeObserver.observe(navbar);
    }
  }

  logoffClicked() {
    this.logOutClicked.emit();
  }

  onSubMenuItemClicked(menuItem: any) {
    this.activeItemId = menuItem.id;
    this.subMenuItemClicked.emit(menuItem);
  }

  onHelpItemSelected(helpItem: any) {
    this.helpMenuItemClicked.emit(helpItem);
  }
}