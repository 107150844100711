import { Injectable } from "@angular/core";
import { ComponentService } from "src/app/services/ComponentService";
import { CmdletBase } from "src/app/common/services/CmdletBase";
import { JobService } from "src/app/common/services/JobService";
import { VoidResult } from "src/app/types/helpers/VoidResult";
import { MergeFunctionTemplateUmdb } from "../types/MergeFunctionTemplateUmdb";
import { UpdateMergeFunctionDefaultTemplateJob } from "../jobs/UpdateMergeFunctionDefaultTemplateJob";
import { MergeFunctionUm4DefaultTemplateReq } from "../types/MergeFunctionUm4DefaultTemplateReq";

@Injectable({ providedIn: 'root' })
export class UpdateMergeFunctionDefaultTemplateCmdlet extends CmdletBase<{model: MergeFunctionUm4DefaultTemplateReq, globalMode: boolean, globalTemplate: boolean, makeDefault: boolean}, VoidResult> {

  constructor(private jobService: JobService, private job: UpdateMergeFunctionDefaultTemplateJob, componentService: ComponentService) {
    super(componentService);
  }

  protected async executeImpl(arg: {model: MergeFunctionUm4DefaultTemplateReq, globalMode: boolean, globalTemplate: boolean, makeDefault: boolean}): Promise<VoidResult> {
    await this.jobService.runJob<UpdateMergeFunctionDefaultTemplateJob, {model: MergeFunctionUm4DefaultTemplateReq, globalMode: boolean, globalTemplate: boolean, makeDefault: boolean}, VoidResult>(this.job, arg);
    return new VoidResult();
  }
}
