<div class="right-content-container">  
  <div *ngIf="!isLoaded">
    <ngx-skeleton-loader appearance="line" count="1" [theme]="{height: '24px'}"></ngx-skeleton-loader>
  </div>
  <app-bread-crumbs *ngIf="isLoaded" [breadcrumbs]="breadcrumbs"></app-bread-crumbs>
 
  <div class="page-toolbar">{{'headers.base_template' | translate}}</div>
  
  <alert></alert>

  <div *ngIf="!isLoaded" class="table-header flex-direction-column">
    <ngx-skeleton-loader appearance="line" count="4" [theme]="{height: '24px'}"></ngx-skeleton-loader>
  </div>

  <div class="table-header flex-direction-column" *ngIf="isLoaded && baseTemplate">
    <p class="panel-header">{{'headers.base_template_details' | translate}}</p>    
    <table class="prop-table">
      <tr>
        <td class="label"><span>{{'labels.name' | translate}}: </span></td>
        <td class="value"><input class="input" [(ngModel)]="editRaetName" id="name" maxlength="50"></td>
      </tr>
      <tr>
        <td class="label"><span>{{'labels.status' | translate}}: </span></td>
        <td class="value">
          <p *ngIf="baseTemplate.isActive" class="description">{{'labels.active' | translate}}</p>
          
          <p *ngIf="!baseTemplate.isActive" class="description">{{'labels.inactive' | translate}}</p>
        </td>
      </tr>            
    </table>  
    <div class="flex-align-end">
      <button (click)="onSaveTemplateClicked($event)" class="editButton3" [disabled]="actionBusy">{{'buttons.save' | translate}}</button>
    </div>
  </div>

  <div *ngIf="!isLoaded" class="table-header flex-direction-column">
    <div class="skeleton-wrapper">
      
      <ngx-skeleton-loader 
        count="1" 
        appearance="rectangle" 
        [theme]="{ height: '300px', width: '200px', 'margin-right': '16px' }">
      </ngx-skeleton-loader>    
      
      <div class="skeleton-lines">
        <ngx-skeleton-loader appearance="line" count="4" [theme]="{height: '24px'}"></ngx-skeleton-loader>
      </div>
    </div>
  </div>

  <div *ngIf="isLoaded" class="table-header">
    <div>
      <p class="panel-header">{{'headers.base_template' | translate}}</p>
      <div class="thumbnail" [ngClass]="{'error': !imagePath}">
        <img *ngIf="imagePath" [src]="imagePath" (error)="onThumbnailError()" alt="Thumbnail image">
        <span *ngIf="!imagePath" class="error-text">{{ 'errors.no_thumbnail' | translate }}</span>
      </div>    
    </div>
    <div class="templateDetails">
      <ul>
        <li>
          <span class="vismaicon vismaicon-dynamic vismaicon-sm vismaicon-edit vertical-middle" aria-hidden="true"></span>
          <button (click)="onEditemplateClicked($event)" class="templateDetailsBtn" [disabled]="actionBusy">{{'buttons.edit' | translate}}</button>
        </li>
        <li>
          <span class="vismaicon vismaicon-dynamic vismaicon-sm vismaicon-refresh vertical-middle" aria-hidden="true"></span>
          <button (click)="onRedoTemplateClicked($event)" class="templateDetailsBtn" [disabled]="actionBusy">{{'buttons.remake' | translate}}</button>
        </li>
        <li>
          <span class="vismaicon vismaicon-dynamic vismaicon-sm vismaicon-delete vertical-middle" aria-hidden="true"></span>
          <button (click)="onDeleteTemplateClicked($event)" class="templateDetailsBtn" [disabled]="actionBusy">{{'buttons.delete' | translate}}</button>
        </li>
        <li> <span class="vismaicon vismaicon-dynamic vismaicon-sm vismaicon-upload vertical-middle" aria-hidden="true"></span>
          <button (click)="onAddNewRowClicked('upload')" class="templateDetailsBtn" [disabled]="actionBusy">{{'buttons.upload_new_content' | translate}}</button>
        </li>
        <li class="pt-16">
          <app-dropdown-button [title]="'buttons.export' | translate" [items]="exportItems" [disabled]="actionBusy"
            (clicked)="onExportTemplateClicked($event)">
          </app-dropdown-button>
        </li>
      </ul>
    </div>
  </div>
</div>