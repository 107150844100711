<div class="right-content-container">  
  <app-bread-crumbs [breadcrumbs]="breadcrumbs"></app-bread-crumbs>  
  <div class="page-toolbar">{{'headers.template_versions' | translate}}</div>  
  <alert></alert>  
  <div class="table">
    <div class="table-body" id="templateVersions">
      <p class="panel-header">{{'headers.template_versions' | translate}}</p>      
      <table *ngIf="!isLoading" mat-table matSort [dataSource]="template?.versions" class="list-items">

        <ng-container matColumnDef="version">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'table_headers.version' | translate}}</th>
            <td mat-cell *matCellDef="let element"> {{element.number.majorNumber}}.{{element.number.minorNumber}} </td>
          </ng-container>

        <ng-container matColumnDef="creationDate">
          <th mat-header-cell *matHeaderCellDef>{{'table_headers.date_created' | translate}}</th>
          <td mat-cell *matCellDef="let element"> {{element.creationDate | date:'short':'':myUser.culture }} </td>
        </ng-container>

        <ng-container matColumnDef="createdBy">
          <th mat-header-cell *matHeaderCellDef>{{'table_headers.created_by' | translate}}</th>
          <td mat-cell *matCellDef="let element"> {{element.createdBy}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>{{'table_headers.name' | translate}}</th>
          <td mat-cell *matCellDef="let element"> {{element.createdBy}} </td>
        </ng-container>

        <ng-container matColumnDef="isActive">
          <th mat-header-cell *matHeaderCellDef></th>
          <td class="width-130 centered" mat-cell *matCellDef="let element">            
            <p *ngIf="element.id === maxId">{{'labels.current_version' | translate}}</p>
            <button *ngIf="element.id !== maxId && (!element.isGlobal || globalMode)" (click)="$event.stopPropagation()" (click)="setActive(element)" class="editButton">{{'buttons.version_make_active' | translate}}</button>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.selected]='row.isSelected'></tr>
      </table>
      <div class="table-skeleton" *ngIf="isLoading">
        <ngx-skeleton-loader appearance="line" count="10" [theme]="{height: '32px'}"></ngx-skeleton-loader>
      </div>
    </div>
  </div>
</div>